import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["customerName", "customerPhone", "receiverName", "receiverPhone"]

  copyCustomerName(e) {
    e.preventDefault()

    let customerName = this.customerNameTarget.value
    this.receiverNameTarget.value = customerName
  }

  copyCustomerPhone(e) {
    e.preventDefault()

    let customerPhone = this.customerPhoneTarget.value
    this.receiverPhoneTarget.value = customerPhone
  }
}
