import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["field"]

  toggle() {
    const target = this.fieldTarget;
    if (target.type === "password") {
      target.type = "text";
    } else {
      target.type = "password";
    }
  }
}
