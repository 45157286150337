import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    checkoutUrl: String
  }

  static outlets = ["checkout-review"]

  checkoutReviewOutletConnected(outlet, element) {
    console.log(outlet, element)
    console.log(this.checkoutReviewOutlet)
  }

  handleSubmit(event) {
    event.preventDefault()

    let form = this.element
    let formData = $(form).serializeArray()
    let urlString = this.checkoutUrlValue

    $.ajax({
      method: form.method,
      url: form.action,
      data: formData,
      dataType: "json",
      success: function() {
        fetch(urlString, { credentials: "include" })
        .then(response => response.text())
        .then(html => {
          let modalBodyElement = document.querySelector("[data-checkout-review-target=modalBody]")
          modalBodyElement.innerHTML = html
          GLightbox.reload()
          $("#checkoutReviewModal").modal()
        })
      },
      error: function(error) {
        console.log(error)
      }
    })
  }
}
