import { Controller } from "@hotwired/stimulus"
import autoComplete from "@tarekraafat/autocomplete.js"

export default class extends Controller {
  static values = {
    rentalId: String,
    autocompletePath: String
  }

  static targets = ["itemId"]

  connect() {
    console.log(this.itemIdTarget)
    new autoComplete({
      placeHolder: "Cari Kode Unit",
      selector: "#items-autocomplete",
      wrapper: false,
      data: {
        src: async (phrase) => {
          try {
            let url = `${this.autocompletePathValue}?phrase=${phrase}`
            const source = await fetch(url)
            const data = await source.json()

            return data.results
          } catch(error) {
            return []
          }
        },
        keys: ["caption"]
      },
      resultsList: {
        class: "mt-2 list-group list-group-flush",
        noResults: true,
        element: (list, data) => {
          if (data.results.length == 0) {
            const info = document.createElement("p");

            info.classList.add("p-3", "bg-warning-200", "shadow-sm", "mb-2")
            info.innerHTML = `Tidak ada hasil untuk <strong>"${data.query}"</strong>`;
            list.prepend(info);
          }
        }
      },
      resultItem: {
        class: "list-group-item list-group-item-action mb-2 border-0 shadow-light",
        highlight: {
            render: true
        },
        element: (item, data) => {
          item.innerHTML = `
            <div class="d-flex align-items-center">
              <img src="${data.value.picture_url}" class="img-fluid h-12 mr-2"/>
              <div>
                <span class="d-block tw-font-semibold mb-1">${data.match}</span>
                <span class="d-block tw-font-medium mb-1">${data.value.product_caption}</span>
              </div>
            </div>
          `
        }
      },
      events: {
        input: {
          selection: (event) => {
            let searchField = document.querySelector("#items-autocomplete")
            let selection = event.detail.selection.value
            let itemId = selection.id

            searchField.value = selection.caption
            this.itemIdTarget.value = itemId
          }
        }
      }
    })
  }
}
