import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["content"]

  updatePreview(e) {
    let font = e.target.options[e.target.selectedIndex].dataset.font
    this.contentTarget.style.fontFamily = `${font}, 'sans-serif'`
  }
}
