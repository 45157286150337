import { Controller } from "@hotwired/stimulus";
import { xOutline } from "../utilities/heroicons";

export default class extends Controller {
  deleteIcon(event) {
    let iconField = document.querySelector("#social_link_item_icon")
    let iconButton = document.querySelector("#iconsSearchModalToggleButton")

    iconField.value = ""
    iconButton.innerHTML = xOutline("tw-h-10 tw-w-10")
    $("#iconsSearchModal").modal("hide")
  }

  selectIcon(event) {
    let iconField = document.querySelector("#social_link_item_icon")
    let iconButton = document.querySelector("#iconsSearchModalToggleButton")

    iconField.value = event.currentTarget.dataset.icon
    iconButton.innerHTML = event.currentTarget.innerHTML
    $("#iconsSearchModal").modal("hide")
  }
}
