import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "startAtDate", "startAtTime", "endAtDate", "endAtTime", "startAt", "endAt",
    "availableList", "itemId", "itemCode", "priceIdr", "discount", "discountLabel",
    "netPrice", "itemWrapper", "priceSuggestion"
  ]

  select(e) {
    this.availableListTarget.classList.add("d-none")

    this.itemWrapperTarget.classList.remove("d-none")
    this.itemCodeTarget.value = e.target.dataset.itemCode
    this.itemIdTarget.value = e.target.dataset.itemId
    this.priceSuggestionTarget.innerHTML = document.getElementById("price_suggestion").innerHTML
    this.priceIdrTarget.value = 0
    this.discountTarget.value = 0
    this.discountLabelTarget.value = ""
    this.netPriceTarget.innerHTML = "0"
    this.startAtTarget.value = `${this.startAtDateTarget.value} ${this.startAtTimeTarget.value}:00`
    this.endAtTarget.value = `${this.endAtDateTarget.value} ${this.endAtTimeTarget.value}:59`
  }
}
