import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["expenseCategoryForm", "submitButton", "errorMessages"]

  connect() {
    $("#newExpenseCategoryModal").on("shown.bs.modal", function (event) {
      document.querySelector("#expense_category_name").focus()
    })
  }

  handleSubmitNewExpenseCategory(e) {
    e.preventDefault()

    let form = this.expenseCategoryFormTarget
    let formData = $(form).serializeArray()
    let submitButton = this.submitButtonTarget

    $.ajax({
      method: form.method,
      url: form.action,
      data: formData,
      dataType: "json",
      success: function(response) {
        let id = response.data.id
        let name = response.data.name
        let option = `<option value="${id}">${name}</option>`
        let select = document.querySelector("#expense_expense_category_id")
        select.insertAdjacentHTML('beforeend', option)
        select.value = id

        let expenseCategoryName = document.querySelector("#expense_category_name")
        expenseCategoryName.value = ""
        $("#newExpenseCategoryModal").modal("hide")
        submitButton.disabled = false
      },
      error: function(error) {
        let errors = error.responseJSON.errors.map(error => {
          return `<li>${error}</li>`
        }).join(" ")

        submitButton.disabled = false

        let flash = `
          <div class="alert-expense-category alert alert-error tw-alert-danger alert-dismissible">
            <button type="button" class="close" data-dismiss="alert" aria-hidden="true">×</button>
            <div class="tw-flex tw-items-center tw-space-x-2 tw-mb-2">
              <!-- Heroicons: outline/exclamation -->
              <svg class="tw-h-6 tw-w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
              </svg>
              <span class="tw-text-base tw-font-semibold">Maaf 🙏</span>
            </div>
            ${errors}
          </div>
        `

        let flashElement = document.querySelector(".alert-expense-category")

        if(typeof(flashElement) != "undefined" && flashElement != null) {
          flashElement.remove()
        }

        form.insertAdjacentHTML("afterbegin", flash)
      }
    })
  }
}
