import { Controller } from "@hotwired/stimulus"
import autoComplete from "@tarekraafat/autocomplete.js"

export default class extends Controller {
  static targets = ["productAutocomplete"]

  static values = {
    collectionPath: String,
    collectionRulePath: String,
    productAutocompletePath: String,
    excludeIds: Array
  }

  connect() {
    if (this.hasProductAutocompleteTarget) {
      this.productAutocomplete()
    }
  }

  addProductCollection(productId, collectionPath) {
    $.ajax({
      method: 'POST',
      url: collectionPath,
      data: {
        product_collection: {
          product_id: productId
        }
      },
      success: function() {
        $('#products-autocomplete').blur()
      }
    })
  }

  productAutocomplete() {
    new autoComplete({
      placeHolder: "Cari Nama Produk",
      selector: "#products-autocomplete",
      wrapper: false,
      data: {
        src: async (phrase) => {
          try {
            const excludeIdsString = this.excludeIdsValue.map(element => `&exclude_ids[]=${element}`).join("")
            const url = `${this.productAutocompletePathValue}?phrase=${phrase}&no_items=true${excludeIdsString}`
            const source = await fetch(url)
            const data = await source.json()

            return data.results
          } catch(error) {
            return []
          }
        },
        keys: ["caption"]
      },
      resultsList: {
        class: "mt-2 list-group list-group-flush overflow-auto",
        noResults: true,
        maxResults: 20,
        element: (list, data) => {
          const info = document.createElement("p");
          info.classList.add("p-3", "bg-warning-200", "shadow-sm", "mb-2")

          if (data.results.length > 0) {
            info.innerHTML = `Menampilkan <strong>${data.results.length}</strong> dari <strong>${data.matches.length}</strong> hasil`;
          } else {
            info.innerHTML = `Tidak ada hasil untuk <strong>"${data.query}"</strong>`;
          }
          list.prepend(info);
        }
      },
      resultItem: {
        class: "list-group-item list-group-item-action mb-2 border-0 shadow-light p-3",
        highlight: {
            render: true
        },
        element: (item, data) => {
          item.innerHTML = `
            <div class="d-flex align-items-center">
              <img src="${data.value.picture_url}" class="img-fluid h-12 mr-2"/>
              <div>
                <span class="d-block text-bold mb-1">${data.match}</span>
                <span class="d-block text-muted">${data.value.type}</span>
              </div>
            </div>
          `
        }
      },
      events: {
        input: {
          selection: (event) => {
            let searchField = document.querySelector("#products-autocomplete")
            let selection = event.detail.selection.value
            this.addProductCollection(selection.id, this.collectionPathValue)

            searchField.value = selection.caption
          }
        }
      }
    })
  }

  handleSelectCategory(e) {
    let collectionRuleId = e.target.value

    $.ajax({
      method: 'POST',
      url: this.collectionRulePathValue,
      data: {
        collection_rule: {
          category_id: collectionRuleId
        }
      },
      success: function() {
        $('#newProductCategory').hide()
      }
    })
  }
}
