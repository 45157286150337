import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  connect() {
    document.addEventListener("click", function(e) {
      var container = $("#bottom-misc-menu-item");

      if (!container.is(e.target) && container.has(e.target).length === 0) {
       if ( $('.bottom-up-menu').hasClass('show') ) {
          $('.bottom-up-menu').removeClass('show');
          $('#bottom-misc-menu-item .chevron-up').toggleClass('tw-hidden');
          $('#bottom-misc-menu-item .dots-vertical').toggleClass('tw-hidden');
          $('#mobile-body-overlay').toggle();
        }
      }
    })

    let element = "input[type=text], input[type=number], input[type=tel], input[type=email], input[type=search], textarea"

    $(element).on('focus', function() {
      $('.bottom-menu').addClass('d-none')
    })

    $(element).on('blur', function() {
      displayMenu()
    })

    function displayMenu() {
      setTimeout(function () {
        $('.bottom-menu').removeClass('d-none')      
      }, 500);
    }
  }

  open(event) {
    event.preventDefault()
    event.stopImmediatePropagation()

    $('.bottom-main-menu').children().removeClass('active')
    $('#bottom-misc-menu-item').toggleClass('active')
    $('.bottom-up-menu').toggleClass('show')
    $('#bottom-misc-menu-item .chevron-up').toggleClass('tw-hidden');
    $('#bottom-misc-menu-item .dots-vertical').toggleClass('tw-hidden');
    $('#mobile-body-overlay').toggle()
  }

  handleInputFocus(event) {
    event.preventDefault()
    event.stopImmediatePropagation()

    $('.bottom-menu').addClass('d-none')
  }

  handleInputBlur(event) {
    event.preventDefault()
    event.stopImmediatePropagation()
    displayMenu()
    
    function displayMenu() {
      setTimeout(function () {
        $('.bottom-menu').removeClass('d-none')      
      }, 500);
    }
  }
}

