import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["province", "regency", "district", "submit"]

  handleSelectProvinceChange() {
    this.populateRegency()
  }

  handleSelectRegencyChange() {
    this.populateDistricts()
  }

  populateRegency() {
    fetch(`${this.data.get('regencyPath')}?province_code=${this.provinceTarget.value}`)
      .then(response => response.json())
      .then(data => {
        let regencyBox = this.regencyTarget
        regencyBox.innerHTML = ''
        regencyBox.removeAttribute('disabled')

        let blank_opt = document.createElement('option')
        blank_opt.innerHTML = "Pilih Kota / Kabupaten"
        regencyBox.appendChild(blank_opt)

        data.regencies.forEach(item => {
          let opt = document.createElement('option')
          opt.value = item.code
          opt.innerHTML = item.name
          regencyBox.appendChild(opt)
          $('#regency-code-select').selectpicker('refresh')
        })
      })
  }

  populateDistricts() {
    fetch(`${this.data.get('districtPath')}?regency_code=${this.regencyTarget.value}`)
      .then(response => response.json())
      .then(data => {
        let districtBox = this.districtTarget
        let submitButton = this.submitTarget
        districtBox.innerHTML = ''
        districtBox.removeAttribute('disabled')
        submitButton.removeAttribute('disabled')

        data.districts.forEach(district => {
          let opt = document.createElement('option')
          opt.value = district.code
          opt.selected = true
          opt.innerHTML = district.name
          districtBox.setAttribute('data-title', "Pilih kecamatan")
          districtBox.appendChild(opt)
          $('#district-code-select').selectpicker('refresh')
        })
      })
  }
}