import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["pickedUpRadio", "adjustEndAtCheckbox"]

  toggleAdjustEndAtCheckbox(){
    if(this.pickedUpRadioTarget.checked) {
      this.adjustEndAtCheckboxTarget.disabled = false
    } else {
      this.adjustEndAtCheckboxTarget.disabled = true
      this.adjustEndAtCheckboxTarget.checked = false
    }
  }
}
