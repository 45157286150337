import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["submitButton", "extensionItemField"]

  static values = {
    invalidFields: Array,
    numberOfExtensionItemFields: Number
  }

  connect() {
    this.checkNumberOfExtensionItemFields()
  }

  checkNumberOfExtensionItemFields() {
    this.numberOfExtensionItemFieldsValue = this.extensionItemFieldTargets.length

    if (this.numberOfExtensionItemFieldsValue === 0) {
      this.submitButtonTarget.disabled = true
    } else {
      this.submitButtonTarget.disabled = false
    }
  }

  invalidFieldsValueChanged(value) {
    if(value.length === 0) {
      this.submitButtonTarget.disabled = false
    } else {
      this.submitButtonTarget.disabled = true
    }
  }
}
