import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets= ['grossPrice', 'discount', 'netPrice']

  connect() {
    if(this.hasGrossPriceTarget && this.hasDiscountTarget && this.hasNetPriceTarget) {
      this.calculatePrice()
    }
  }

  calculatePrice() {
    // setTimeout is used to wait for CleaveJs onValueChanged return
    setTimeout(() => {
      const grossValue = this.grossPriceTarget.value
      const discountValue = this.discountTarget.value;
      const netPriceValue = grossValue - discountValue;
      this.netPriceTarget.innerHTML = netPriceValue.toLocaleString("id");
    }, 100)
  }
}
