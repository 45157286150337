import { Controller } from "@hotwired/stimulus"
import Quill from "quill";

export default class extends Controller {
  static targets = ["editor", "form", "content"]

  connect() {
    let toolbarOptions = {
      container: [
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
        ["link"],
      ]
    }

    this.quill = new Quill(this.editorTarget, {
      modules: {
        toolbar: toolbarOptions
      },
      theme: "snow"
    })

    this.formTarget.addEventListener("submit", () => {
      this.contentTarget.value = this.quill.root.innerHTML
    })
  }
}