import { Controller } from "@hotwired/stimulus"
import Cleave from "cleave.js"

export default class extends Controller {
  static targets = ["submit", "formDiv", "amountFormatted", "inputFormatted", "inputRaw"]
  static values = {
    unpaidAmount: String
  }

  connect() {
    let inputFormattedElement = this.inputFormattedTarget
    let inputRawElement = this.inputRawTarget

    this.cleaveJs = new Cleave(inputFormattedElement, {
      numeral: true,
      numericOnly: true,
      numeralDecimalMark: ',',
      delimiter: '.',
      onValueChanged: function (e) {
        inputRawElement.value = e.target.rawValue
      }
    })
  }

  writeUnpaidAmount(event){
    event.preventDefault()
    const unpaidAmount = this.unpaidAmountValue
    this.cleaveJs.setRawValue(unpaidAmount)
  }

  checkAmountValidity(event){
    setTimeout(() => {
      const currentAmount = parseInt(this.inputRawTarget.value, 10)
      const unpaidAmount = parseInt(this.unpaidAmountValue, 10)
      
      if (currentAmount > unpaidAmount) {
        this.submitTarget.disabled = true
        this.submitTarget.classList.add("tw-btn-muted")
        this.inputFormattedTarget.classList.add('is-invalid')
        this.addErrorMessage()
      }
      else {
        this.submitTarget.disabled = false
        this.submitTarget.classList.remove("tw-btn-muted")
        this.inputFormattedTarget.classList.remove('is-invalid')
        this.removeErrorMessage()
      }
    }, 100)
  }

  removeErrorMessage(){
    let errorDiv = document.getElementById('payment_error_message');
    if (errorDiv) {
      errorDiv.parentNode.removeChild(errorDiv);
    }
  }

  addErrorMessage(){
    if (!document.getElementById('payment_error_message')) {
      let errorDiv = document.createElement('div')
      errorDiv.className = 'invalid-feedback tw-text-sm'
      errorDiv.id = 'payment_error_message'
      errorDiv.innerHTML = 'Pembayaran melebihi jumlah tagihan'

      this.formDivTarget.appendChild(errorDiv)
    }
  }
}
