import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    $(".select2").select2({
      tags: true,
      tokenSeparators: [","],
      placeholder: "Pisahkan beberapa kategori dengan koma. Contoh: tas, travel, backpack"
    })
  }
}
