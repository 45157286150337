import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["quantity", "submitButton", "newCounterLabel"]
  static values = {
    totalItems: Number,
    newItemsCounter: Number,
    counter: Number
  }

  connect() {
    this.counterValue = this.totalItemsValue
  }
  
  increaseStock(e) {
    this.quantityTarget.value = parseInt(this.quantityTarget.value) + 1
    this.counterValue = this.counterValue + 1
    this.updateCounterLabel()
  }

  reduceStock(e) {
    this.quantityTarget.value = parseInt(this.quantityTarget.value) - 1
    this.counterValue = this.counterValue - 1
    this.updateCounterLabel()
  }

  updateCounterLabel() {
    this.newCounterLabelTarget.innerHTML = this.counterValue
  }

  handleUpdateQuantity(e) {
    let value = e.target.value.length === 0 ? 0 : parseInt(e.target.value)
    this.counterValue = this.totalItemsValue + value
    this.updateCounterLabel()
  }

  handleSubmitLabel(e) {
    let counter = e.target.value

    if(counter.length != 0 && parseInt(counter) > 0) {
      this.buttonTarget.disabled = false
      this.buttonTarget.classList.add("tw-btn-primary")
      this.buttonTarget.classList.remove("tw-btn-muted")
      this.buttonTarget.value = `Tambah ${counter} stok`
      e.target.classList.remove('is-invalid')
      this.buttonTarget.type = "submit"
    } else {
      this.buttonTarget.disabled = true
      this.buttonTarget.value = "Tambah stok"
      this.buttonTarget.type = "button"
      this.buttonTarget.classList.remove("tw-btn-primary")
      this.buttonTarget.classList.add("tw-btn-muted")
      e.target.classList.add("is-invalid")
    }
  }
}
