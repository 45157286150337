import * as dateUtils from '../packs/date_utils'

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    $('#start_date').on('change', function() {
      dateUtils.adjustStartLimit($(this), $('#end_date'), 0);
    })

    $('#end_date').on('change', function() {
      dateUtils.adjustFinishLimit($('#start_date'), $(this), 0);
    })
  }
}