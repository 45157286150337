import { Controller } from "@hotwired/stimulus"
import Core from "@uppy/core"
import FileInput from "@uppy/file-input"
import AwsS3 from "@uppy/aws-s3"
import Informer from "@uppy/informer"
import ProgressBar from "@uppy/progress-bar"
import ThumbnailGenerator from "@uppy/thumbnail-generator"

export default class extends Controller {
  connect() {
    document.querySelectorAll('.upload-file').forEach(fileInput => {
      this.fileUpload(fileInput)
    })
  }

  fileUpload(fileInput) {
    const hiddenInput = document.querySelector('.upload-hidden')
    const imagePreview = document.querySelector('.upload-preview img')
    const formGroup = fileInput.parentNode

    formGroup.removeChild(fileInput)

    const uppy = new Core({
      autoProceed: true,
      allowMultipleUploadBatches: false,
      restrictions: {
        allowedFileTypes: this.data.get('allowedTypes').split(',')
      }
    })
    .use(FileInput, {
      target: formGroup,
      locale: {
        strings: {
          chooseFiles: "Pilih Gambar",
          failedToUpload: "Gagal mengunggah gambar %{file}"
        }
      }
    })
    .use(Informer, { target: formGroup })
    .use(ProgressBar, { target: imagePreview.parentNode })
    .use(ThumbnailGenerator, { thumbnailHeight: 300 })
    .use(AwsS3, { companionUrl: '/' })

    uppy.on('thumbnail:generated', (file, preview) => {
      imagePreview.src = preview
    })

    uppy.on('upload-success', (file, response) => {
      const uploadedFileData = {
        id: file.meta['key'].match(/^cache\/(.+)/)[1],
        storage: 'cache',
        metadata: {
          size: file.size,
          filename: file.name,
          mime_type: file.type
        }
      }

      hiddenInput.value = JSON.stringify(uploadedFileData)
    })

    uppy.on('complete', (result) => {
      $('#new_picture').submit()
    })
  }
}
