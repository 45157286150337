/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

import "jquery"
import 'bootstrap/dist/js/bootstrap';
import './jquery.easy-autocomplete'
import 'main'
import 'pickadate/lib/picker'
import 'pickadate/lib/picker.date'
import 'pickadate/lib/picker.time'
import "select2"
import "jtsage-datebox-bootstrap4"
import GLightbox from 'glightbox'
import Trix from "trix"
import "@rails/actiontext"
import "../components"

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
// import "channels"

import "@tabler/icons-webfont/tabler-icons.css"
import "pickadate/lib/themes/default.css"
import "pickadate/lib/themes/default.date.css"
import "pickadate/lib/themes/default.time.css"

Rails.start()
Turbolinks.start()
ActiveStorage.start()

document.addEventListener("turbolinks:before-cache", () => {
  $('[data-toggle="tooltip"]').tooltip('hide');
})

document.addEventListener("turbolinks:load", () => {
  $('[data-toggle="tooltip"]').tooltip({
    trigger: "hover",
    placement: "bottom"
  })
  window.GLightbox = new GLightbox()
})

import "controllers"

document.addEventListener("trix-file-accept", function(event) {
  let allowedTypes = ["image/jpeg", "image/jpg", "image/png", "image/gif", "image/webp"]
  if (event.file && !allowedTypes.includes(event.file.type)) {
    event.preventDefault()
    $("#notSupportedFileTypeModal").modal("toggle")
  }
})

document.addEventListener("trix-attachment-remove", function(event) {
  console.log(event.attachment)
})

Trix.config.lang.captionPlaceholder = "Tambahkan caption"