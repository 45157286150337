import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "pickedUpRadio", "adjustEndAtCheckbox", "adjustEndAtLabel",
    "adjustEndAtAlert", "adjustEndAtField"
  ]

  static values = {
    statusPickedUp: Boolean,
    showAdjustEndAtAlert: Boolean
  }

  toggleEndAtCheckbox(){
    if(this.pickedUpRadioTarget.checked) {
      if (this.showAdjustEndAtAlertValue) {
        this.adjustEndAtAlertTarget.classList.remove("tw-hidden")

        this.adjustEndAtCheckboxTarget.classList.remove("tw-border-gray-300")
        this.adjustEndAtCheckboxTarget.classList.add("tw-border-amber-600")

        this.adjustEndAtLabelTarget.classList.add("peer-disabled:tw-text-amber-600")
        this.adjustEndAtLabelTarget.classList.remove("peer-disabled:tw-text-gray-400")
      } else {
        this.adjustEndAtCheckboxTarget.disabled = false
      }
    } else {
      if (this.showAdjustEndAtAlertValue) {
        this.adjustEndAtAlertTarget.classList.add("tw-hidden")

        this.adjustEndAtCheckboxTarget.classList.remove("peer-disabled:tw-border-amber-600", "tw-border-amber-600")
        this.adjustEndAtCheckboxTarget.classList.add("tw-border-gray-300")

        this.adjustEndAtLabelTarget.classList.remove("peer-disabled:tw-text-amber-600")
        this.adjustEndAtLabelTarget.classList.add("peer-disabled:tw-text-gray-400")
      } else {
        this.adjustEndAtCheckboxTarget.disabled = true
        this.adjustEndAtCheckboxTarget.checked = false
      }
    }
  }
}