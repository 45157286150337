import { Controller } from "@hotwired/stimulus"
import { dateString } from "../packs/date_utils"
import htmlToElement from "../utilities/html_to_element"

export default class extends Controller {
  static values = {
    id: String,
    unitCode: String,
    name: String,
    durationInDays: String,
    priceSuggestion: { type: Number, default: 0 }
  }

  static outlets = ["search-units", "new-order", "order-add-items"]

  connect() {
    if (this.hasSearchUnitsOutlet) {
      if(this.searchUnitsOutlet.hasPriceReminderTarget) {
        this.priceReminderDiv = this.searchUnitsOutlet.priceReminderTarget
      }

      if(this.searchUnitsOutlet.hasInitialItemListTarget) {
        this.initialPrompt = this.searchUnitsOutlet.initialItemListTarget
      }

      this.startDateValue = this.searchUnitsOutlet.startAtDateTarget.value
      this.startTimeValue = this.searchUnitsOutlet.startAtTimeTarget.value

      this.endDateValue = this.searchUnitsOutlet.endAtDateTarget.value
      this.endTimeValue = this.searchUnitsOutlet.endAtTimeTarget.value
    }
  }

  add() {
    const mSec = (new Date).getTime()
    const id = this.idValue
    const name = this.nameValue
    const unitCode = this.unitCodeValue
    const searchUnitsController = this.searchUnitsOutlet

    const bookedIds = searchUnitsController.bookedIdsValue
    bookedIds.push(id)
    searchUnitsController.bookedIdsValue = bookedIds

    // Order item section
    const startDateCaption = dateString(this.startDateValue, this.startTimeValue)
    const endDateCaption = dateString(this.endDateValue, this.endTimeValue)
    const orderItemDetail = `
      <div class="mb-3">
        <div class="row mb-2">
          <div class="col-12 col-lg-3 tw-font-semibold tw-text-gray-700">Masa sewa</div>
          <div class="col-12 col-lg-9 tw-text-gray-700">
            ${startDateCaption} - ${endDateCaption}
            <span class="tw-font-semibold">(${this.durationInDaysValue} hari)</span>
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-12 col-lg-3 tw-font-semibold tw-text-gray-700">Nama produk</div>
          <div class="col-12 col-lg-9 tw-text-gray-700">${name}</div>
        </div>
        <div class="row mb-2">
          <div class="col-12 col-lg-3 tw-font-semibold tw-text-gray-700">Kode Unit</div>
          <div class="col-12 col-lg-9 tw-text-gray-700">${unitCode}</div>
        </div>
      </div>
    `

    // Item id form field
    const inputItemId = document.createElement('INPUT')
    inputItemId.setAttribute('type', 'hidden')
    inputItemId.setAttribute('id', `order_order_items_attributes_${mSec}_item_id`)
    inputItemId.setAttribute('name', `order[order_items_attributes][${mSec}][item_id]`)
    inputItemId.setAttribute('value', id)

    // Start at form field
    const inputStartAt = document.createElement('INPUT')
    inputStartAt.setAttribute('type', 'hidden')
    inputStartAt.setAttribute('id', `order_order_items_attributes_${mSec}_start_at`)
    inputStartAt.setAttribute("name", `order[order_items_attributes][${mSec}][start_at]`)
    inputStartAt.setAttribute("value", `${this.startDateValue} ${this.startTimeValue}:00`)

    // End at form field
    const inputEndAt = document.createElement('INPUT')
    inputEndAt.setAttribute('type', 'hidden')
    inputEndAt.setAttribute('id', `order_order_items_attributes_${mSec}_end_at`)
    inputEndAt.setAttribute("name", `order[order_items_attributes][${mSec}][end_at]`)
    inputEndAt.setAttribute("value", `${this.endDateValue} ${this.endTimeValue}:59`)

    // Input price idr form field
    const inputPriceIdrHTML = `
      <div class="form-group" data-controller="cleavejs">
        <label for="price-${mSec}" class="tw-font-semibold tw-text-gray-700">Harga sewa</label>

        <input
          type="text"
          inputmode="numeric"
          class="tw-form-control input--price"
          id="price-${mSec}"
          data-cleavejs-target="inputFormatted"
          data-action="focus->menu#handleInputFocus blur->menu#handleInputBlur input->discount-input#calculatePrice"
          value="${ this.priceSuggestionValue }"
        />

        <input
          type="hidden"
          id="order_order_items_attributes_${mSec}_price_idr"
          name="order[order_items_attributes][${mSec}][price_idr]"
          data-discount-input-target="grossPrice"
          data-cleavejs-target="inputRaw"
          value="${ this.priceSuggestionValue }"
        />
      </div>
    `
    const priceDiv = htmlToElement(inputPriceIdrHTML)
    const suggestionDivHTML = `<div id='price_suggestion_${mSec}' class='text-right'></div>`
    const suggestionDiv = htmlToElement(suggestionDivHTML)
    suggestionDiv.innerHTML = document.getElementById('price_suggestion').innerHTML

    // Discount input form field
    const inputDiscountHTML = `
      <div>
        <div class="form-group" data-controller="cleavejs">
          <label for="discount-price-${mSec}" class="tw-font-semibold tw-text-gray-700">Diskon</label>

          <input type="text"
            id="discount-price-${mSec}"
            class="tw-form-control input--price" value="0"
            inputmode="numeric"
            data-cleavejs-target="inputFormatted"
            data-action="focus->menu#handleInputFocus blur->menu#handleInputBlur input->discount-input#calculatePrice" />

          <input type="hidden" id="order_order_items_attributes_${mSec}_discount"
            name="order[order_items_attributes][${mSec}][discount]"
            data-discount-input-target="discount"
            data-cleavejs-target="inputRaw"
            value="0" />
        </div>
        <div class="form-group">
          <label for="order_order_items_attributes_${mSec}_discount_label" class="tw-font-semibold tw-text-gray-700">Label Diskon (opsional)</label>
          <input type="text" id="order_order_items_attributes_${mSec}_discount_label"
            name="order[order_items_attributes][${mSec}][discount_label]" class="tw-form-control"
            data-action="focus->menu#handleInputFocus blur->menu#handleInputBlur"
            placeholder="contoh: Promo Gajian">
        </div>
      </div>
    `
    const discountDiv = htmlToElement(inputDiscountHTML)
    const netPriceHTML = `
      <div class="d-flex align-items-center justify-content-between mb-3">
        <div class="tw-font-semibold tw-text-gray-700">Harga Net:</div>
        <div class="tw-text-gray-700 text-right pr-3">Rp<span data-discount-input-target="netPrice">0</span></div>
      </div>
    `
    const netPriceDiv = htmlToElement(netPriceHTML)

    // Remove button
    const removeButton = `
      <button
        class="tw-btn tw-btn-danger-outline tw-min-w-[8rem]"
        data-action="search-units#removeItem"
        data-item-id="${id}"
      >
        <svg xmlns="http://www.w3.org/2000/svg" class="tw-h-5 tw-w-5 tw-mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
          <path stroke-linecap="round" stroke-linejoin="round" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
        </svg>
        Hapus
      </button>
    `
    const removeButtonHTML = htmlToElement(removeButton)

    // Construct order item form field section
    const orderItemDiv = document.createElement("div")
    orderItemDiv.setAttribute("data-controller", "discount-input")
    orderItemDiv.setAttribute("class", "tw-p-4 tw-border tw-rounded animate-flash-green")

    orderItemDiv.innerHTML = orderItemDetail
    orderItemDiv.appendChild(inputItemId)
    orderItemDiv.appendChild(inputStartAt);
    orderItemDiv.appendChild(inputEndAt)
    orderItemDiv.appendChild(priceDiv)
    orderItemDiv.appendChild(suggestionDiv)
    orderItemDiv.appendChild(discountDiv);
    orderItemDiv.appendChild(netPriceDiv);
    orderItemDiv.appendChild(removeButtonHTML)

    searchUnitsController.orderItemsListTarget.prepend(orderItemDiv)

    // hide initial prompt and display reminder
    if (searchUnitsController.hasInitialItemListTarget){
      this.initialPrompt.classList.add("d-none")
    }

    if (searchUnitsController.hasPriceReminderTarget) {
      this.priceReminderDiv.classList.remove("d-none")
    }

    // Remove modal detail
    const modalDetail = document.querySelector(`#item_${id}_modal`)
    this.element.parentNode.removeChild(modalDetail)

    // remove button
    this.element.parentNode.removeChild(this.element)

    // Enable submit button only for add items page
    if (this.hasOrderAddItemsOutlet) {
      this.orderAddItemsOutlet.submitButtonTarget.disabled = false
    }

    // enable next button
    if (this.hasNewOrderOutlet) {
      this.newOrderOutlet.orderItemNextCollapseButtonTarget.classList.remove("disabled-next")
    }
  }
}
