import { Controller } from "@hotwired/stimulus"
import * as dateUtils from "../packs/date_utils"

export default class extends Controller {
  static targets = ["startDate", "endDate", "durationDay"]
  static values = { includeStartDateOnDuration: { type: Boolean, default: false } }

  connect() {
    $(this.startDateTarget).on("change", () => {
      dateUtils.adjustStartLimit($(this.startDateTarget), $(this.endDateTarget), 0);

      if (this.hasDurationDayTarget && this.endDateTarget.value != "") {
        this.writeDurationDay()
      }
    })

    $(this.endDateTarget).on("change", () => {
      dateUtils.adjustFinishLimit($(this.startDateTarget), $(this.endDateTarget), 0);

      if (this.hasDurationDayTarget && this.startDateTarget.value != "") {
        this.writeDurationDay()
      }
    })
  }

  writeDurationDay() {
    const startDateString = this.startDateTarget.value
    const endDateString = this.endDateTarget.value
    const startDate = dateUtils.dateFromStringShort(startDateString)
    const endDate = dateUtils.dateFromStringShort(endDateString)

    if (startDate && endDate) {
      const period = dateUtils.daysBetween(startDate, endDate)
      this.durationDayTarget.innerHTML = `(${this.includeStartDateOnDurationValue ? (period + 1) : period} hari)`
    }
  }
}
