import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  connect() {
    // if (window.refreshTimer) {
    //   window.clearTimeout(window.refreshTimer)
    // }
    // const refresh_timeout = 25 * 60 * 1000 // 25 minutes
    // window.refreshTimer = window.setTimeout(this.launchModal, refresh_timeout)
  }

  launchModal() {
    window.clearTimeout(window.refreshTimer)
    $('#refreshModal').modal({backdrop: 'static', keyboard: false})
  }

  refresh() {
    Turbolinks.visit(window.location, { action: 'replace' })
  }

}
