import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  insertAdditional(event) {
    event.preventDefault()

    const mSec = (new Date).getTime()
    const additionalCostRow = document.getElementById('additional_costs_row')
    const additionalCostPresets = JSON.parse(event.target.dataset.costPresents).presets

    let additionalCostItem = `
      <input
        id="additional_cost_${mSec}_destroy"
        autocomplete="off"
        type="hidden"
        value="false"
        name="order[additional_costs_attributes][${mSec}][_destroy]"
      />

      <div
        id="additional_cost_${mSec}"
        class="additional-cost tw-bg-white tw-p-4
              tw-shadow tw-border md:tw-rounded"
      >
        <div class="form-group tw-mb-4">
          <label
            for="order_additional_costs_attributes_${mSec}_description"
            class="tw-font-semibold tw-text-gray-700"
          >
            Keterangan
          </label>

          <input 
            type="text"
            class="tw-form-control mb-2"
            id="order_additional_costs_attributes_${mSec}_description"
            name="order[additional_costs_attributes][${mSec}][description]"
            placeholder="'Ongkos Kirim', 'Diskon', dsb."
          />

          <div class="tw-snap-x tw-pb-2 tw-space-x-2 tw-overflow-y-hidden tw-overflow-x-scroll tw-whitespace-nowrap"> 
            ${this.renderAdditionalCostBubble(additionalCostPresets, mSec)}
          </div>
        </div>

        <div class="form-group" data-controller="cleavejs">
          <label  
            for="price_${mSec}"
            class="text-bold">Harga</label>
          <input
            id="price_${mSec}"
            type="text"
            inputmode="numeric"
            class="tw-form-control input--price"
            value="0"
            data-cleavejs-target="inputFormatted"
            data-action="focus->menu#handleInputFocus blur->menu#handleInputBlur"
          />

          <input
            type="hidden"
            value="0"
            data-cleavejs-target="inputRaw"
            id="order_additional_costs_attributes_${mSec}_price"
            name="order[additional_costs_attributes][${mSec}][price]"
          />
        </div>

        <button
          type="button"
          class="tw-btn tw-btn-danger-outline"
          data-id="additional_cost_${mSec}"
          data-action="additional-costs#removeAdditional"
        >
          <!-- Heroicon: outline/trash -->
          <svg xmlns="http://www.w3.org/2000/svg" class="tw-h-5 tw-w-5 tw-mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
            <path stroke-linecap="round" stroke-linejoin="round" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
          </svg>
          Hapus
        </button>
      </div>
    `

    additionalCostRow.insertAdjacentHTML('beforeend', additionalCostItem)
  }

  renderAdditionalCostBubble(presets, mSec) {
    let bubbles = []

    for(let i = 0; i < presets.length; i++) {
      bubbles.push(
        `
          <span
            class="tw-snap-start tw-inline-block tw-font-semibold btn-red tw-py-2
                    tw-cursor-pointer tw-px-4 rounded-pill tw-text-xs"
            data-action="click->additional-costs#fillAdditionalCostDesc"
            data-description-id="order_additional_costs_attributes_${mSec}_description"
          >
            ${presets[i]}
          </span>
        `
      )
    }

    return bubbles.join(" ")
  }

  removeAdditional(event) {
    event.preventDefault()
    let id = event.target.dataset.id
    let destroyField = document.getElementById(`${id}_destroy`)
    let element = document.getElementById(id)
    destroyField.value = true

    element.remove()
  }

  fillAdditionalCostDesc(e) {
    e.preventDefault()
    let descriptionId = e.target.dataset.descriptionId
    let descriptionInput = document.getElementById(descriptionId)
    let text = e.target.innerText

    descriptionInput.value = text
  }
}
