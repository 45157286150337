import { Controller } from "@hotwired/stimulus"
import { minusSmOutline, trashOutline } from "../utilities/heroicons"

export default class extends Controller {
  static targets = [ 'fields' ]

  static values = {
    costPresets: String
  }

  insertCost(event) {
    const mSec = (new Date).getTime()
    const fieldsDiv = this.fieldsTarget
    const additionalCostPresets = JSON.parse(this.costPresetsValue).presets

    let additionalCostItem = `
      <div
        class="additional-cost tw-p-4 tw-border lg:tw-rounded">
        <div class="form-group mb-2">
          <label
            for="extension_additional_costs_attributes_${mSec}_description"
            class="tw-font-semibold tw-text-gray-700"
          >
            Keterangan
          </label>

          <input
            type="text"
            class="tw-form-control mb-2"
            id="extension_additional_costs_attributes_${mSec}_description"
            name="extension[additional_costs_attributes][${mSec}][description]"
            placeholder="'Ongkos Kirim', 'Diskon', dsb."
          />

          <div class="tw-snap-x tw-pb-2 tw-space-x-2 tw-overflow-y-hidden tw-overflow-x-scroll tw-whitespace-nowrap"> 
            ${this.renderAdditionalCostBubble(additionalCostPresets, mSec)}
          </div>
        </div>

        <div class="form-group" data-controller="cleavejs">
          <label
            for="price_${mSec}"
            class="tw-font-semibold tw-text-gray-700"
          >
            Harga
          </label>

          <div class="tw-flex tw-rounded">
            <button
              type="button"
              class="tw-text-xs tw-border-gray-300 tw-mr-2 tw-btn tw-px-3
                    tw-btn-default tw-w-max tw-shadow-none"
              data-action="cleavejs#toggleSign"
              data-toggle="tooltip"
              data-title="Ubah tanda"
            >
              ${minusSmOutline("tw-h-4 tw-w-4 tw-stroke-current tw-stroke-[3px]")}
            </button>

            <input
              id="price_${mSec}"
              type="text"
              inputmode="numeric"
              class="tw-form-control input--price"
              data-cleavejs-target="inputFormatted"
              data-action="focus->menu#handleInputFocus blur->menu#handleInputBlur"
              value="0"
            />
          </div>


          <input
            type="hidden"
            id="extension_additional_costs_attributes_${mSec}_price"
            name="extension[additional_costs_attributes][${mSec}][price]"
            data-cleavejs-target="inputRaw"
            value="0"
          />
        </div>

        <div class="tw-flex tw-items-center tw-justify-end">
          <button
            type="button"
            class="tw-btn tw-btn-danger-outline tw-min-w-[8rem]"
            data-id="additional_cost_${mSec}"
            data-action="extension-additional#removeAdditional"
          >
            ${trashOutline("tw-h-4 tw-w-4 tw-mr-2")}
            Hapus
          </button>
        </div>
      </div>
    `

    fieldsDiv.insertAdjacentHTML('beforeend', additionalCostItem)
    $('[data-toggle="tooltip"]').tooltip({trigger: "hover", placement: "bottom"}) // Initiate tooltip for new additional cost element
  }

  renderAdditionalCostBubble(presets, mSec) {
    let bubbles = []

    for(let i = 0; i < presets.length; i++) {
      bubbles.push(
        `
          <span 
            class="tw-snap-start tw-inline-block tw-font-semibold tw-btn-default tw-py-2
                  tw-cursor-pointer tw-px-4 rounded-pill tw-text-xs"
            data-action="click->extension-additional#fillAdditionalCostDesc"
            data-description-id="extension_additional_costs_attributes_${mSec}_description"
          >
            ${presets[i]}
          </span>
        `
      )
    }

    return bubbles.join(" ")
  }

  fillAdditionalCostDesc(e) {
    e.preventDefault()
    let descriptionId = e.target.dataset.descriptionId
    let descriptionInput = document.getElementById(descriptionId)
    let text = e.target.innerText

    descriptionInput.value = text
  }

  removeAdditional(event) {
    event.preventDefault()

    let item = event.target.closest(".additional-cost")
    item.remove()
  }
}
