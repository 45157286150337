
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["phoneField", "phoneConfirmationField", "submitButton"]

  connect() {
    this.submitButtonTarget.disabled = true
    this.phoneConfirmationFieldTarget.addEventListener("paste", (e) => e.preventDefault())
  }

  checkPhone() {
    const phoneField              = this.phoneFieldTarget
    const phoneConfirmationField  = this.phoneConfirmationFieldTarget
    const submitButton            = this.submitButtonTarget

    if (phoneField.value != "" && phoneConfirmationField.value != ""){
      if (phoneField.value == phoneConfirmationField.value) {
        submitButton.disabled = false
        phoneField.closest(".form-group").classList.remove("field_with_errors")
        phoneConfirmationField.closest(".form-group").classList.remove("field_with_errors")
      } else {
        submitButton.disabled = true
        phoneField.closest(".form-group").classList.add("field_with_errors")
        phoneConfirmationField.closest(".form-group").classList.add("field_with_errors")
      }
    }
  }
}