import * as dateUtils from '../packs/date_utils'

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "startAt", "startAtDate", "startAtTime",
    "endAt", "endAtDate", "endAtTime",
    "alert", "submitButton"
  ]

  connect() {
    var thisController = this
    var orderItemId = this.data.get('orderItemId');
    var startAtString = this.startAtDateTarget.value
    var endAtString = this.endAtDateTarget.value

    var startAt = dateUtils.dateFromStringShort(startAtString);
    var endAt   = dateUtils.dateFromStringShort(endAtString)

    $(this.startAtDateTarget).pickadate({
      container: '.pickadate-container',
      weekdaysShort: ['Min', 'Sen', 'Sel', 'Rab', 'Kam', 'Jum', 'Sab'],
      today: 'Hari ini',
      clear: false,
      close: 'Tutup',
      formatSubmit: '',
      format: 'dd-mmm-yyyy',
      max: new Date(endAt.setDate(endAt.getDate()))
    });

    $(this.endAtDateTarget).pickadate({
      container: '.pickadate-container',
      weekdaysShort: ['Min', 'Sen', 'Sel', 'Rab', 'Kam', 'Jum', 'Sab'],
      today: 'Hari ini',
      clear: false,
      close: 'Tutup',
      formatSubmit: '',
      format: 'dd-mmm-yyyy',
      min: new Date(startAt.setDate(startAt.getDate()))
    });

    $(this.startAtDateTarget).on('change', function() {
      dateUtils.adjustStartLimit($(this), $(thisController.endAtDateTarget), 0);

      thisController.adjustStartAt(
        thisController.startAtDateTarget.value,
        thisController.startAtTimeTarget.value
      )
      thisController.validateDateTime()
    })

    $(this.startAtTimeTarget).on('change', function() {
      thisController.adjustStartAt(
        thisController.startAtDateTarget.value,
        thisController.startAtTimeTarget.value
      )
      thisController.validateDateTime()
    })

    $(this.endAtDateTarget).on('change', function() {
      dateUtils.adjustFinishLimit($(thisController.startAtDateTarget), $(this), 0);

      thisController.adjustEndAt(
        thisController.endAtDateTarget.value,
        thisController.endAtTimeTarget.value
      )
      thisController.validateDateTime()
    })

    $(this.endAtTimeTarget).on('change', function() {
      thisController.adjustEndAt(
        thisController.endAtDateTarget.value,
        thisController.endAtTimeTarget.value
      )
      thisController.validateDateTime()
    })
  }

  adjustStartAt(date, time) {
    // the "00" part becomes second for hourly orders and will be ignored by ruby Time.zone.parse for non-hourly orders
    this.startAtTarget.value = `${date} ${time}:00`
  }

  adjustEndAt(date, time) {
    // the "00" part becomes second for hourly orders and will be ignored by ruby Time.zone.parse for non-hourly orders
    this.endAtTarget.value = `${date} ${time}:00`
  }

  validateDateTime() {
    let startAt = this.startAtTarget.value
    let endAt   = this.endAtTarget.value
    let isValid = dateUtils.startTimeLessThanEndTime(startAt,endAt)

    this.alertTarget.innerHTML = ""
    this.alertTarget.classList.add("d-none")
    this.startAtTimeTarget.classList.remove("is-invalid")
    this.endAtTimeTarget.classList.remove("is-invalid")
    this.submitButtonTarget.disabled = false

    if (!isValid) {
      event.preventDefault()
      this.alertTarget.innerHTML = '<p class="mb-1 notice text-alert"><i class="fa fa-exclamation-circle notice-icon"></i>Waktu mulai tidak boleh lebih dari atau sama dengan waktu selesai.</p>'
      this.alertTarget.classList.remove("d-none")
      this.startAtTimeTarget.classList.add("is-invalid")
      this.endAtTimeTarget.classList.add("is-invalid")
      this.submitButtonTarget.disabled = true
    }
  }
}
