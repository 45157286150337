import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["districtCodeLabel", "districtCode", "regenciesList", "districtsList"]

  initialize() {
    this.provinceName = ""
    this.regencyName = ""
    this.districtName = ""
  }

  connect() {
    if (this.hasDistrictCodeLabelTarget) {
      $(this.districtCodeLabelTarget).on('focus', function() {
        $('#districtCodeModal').modal('show')
      })
    }
  }

  handleSelectProvince(event) {
    let provinceId = event.target.dataset.provinceId
    let regenciesUrl = this.data.get("regenciesUrl")
    let regenciesUrlString = `${regenciesUrl}?province_code=${provinceId}&select_action=click-%3Edistrict-code%23handleSelectRegency`
    this.provinceName = event.target.dataset.provinceName
    let regenciesListElement = this.regenciesListTarget

    fetch(regenciesUrlString, {credential: "include"})
      .then(response => response.text())
      .then(html => {
        regenciesListElement.innerHTML = html
        var active = $('.wizard .nav-tabs li.active');
        active.next().removeClass('disabled');
        $(active).next().find('a[data-toggle="tab"]').trigger('click');
      })
  }

  handleSelectRegency(event) {
    let regencyCode = event.target.dataset.regencyCode
    let districtsUrl = this.data.get("districtsUrl")
    let districtsUrlString = `${districtsUrl}?regency_code=${regencyCode}&select_action=click-%3Edistrict-code%23handleSelectDistrict`
    let districtsListElement = this.districtsListTarget
    this.regencyName = event.target.dataset.regencyName
   
    fetch(districtsUrlString, {credential: "include"})
      .then(response => response.text())
      .then(html => {
        districtsListElement.innerHTML = html
        var active = $('.wizard .nav-tabs li.active');
        active.next().removeClass('disabled');
        $(active).next().find('a[data-toggle="tab"]').trigger('click');
      })
  }

  handleSelectDistrict(event) {
    $('.btn-coverage-district.active').removeClass('active');
    event.target.classList.add("active")
    this.districtName = event.target.dataset.districtName
    this.districtCodeLabelTarget.value = `${this.districtName}, ${this.regencyName}, ${this.provinceName}`
    this.districtCodeTarget.value = event.target.dataset.districtCode
    $('#districtCodeModal').modal('hide')
  }

  dismissModal() {
    $('#districtCodeModal').modal('hide')
  }
}
