import { Controller } from "@hotwired/stimulus"
import autoComplete from "@tarekraafat/autocomplete.js"
import Turbolinks from 'turbolinks'
import htmlToElement from '../utilities/html_to_element'
import { xCircleSolid } from '../utilities/heroicons'

export default class extends Controller {
  static values = {
    autocompletePath: String
  }

  connect() {
    $("#customerSearchModal").on("shown.bs.modal", () => {
      $("#customer-search").trigger("focus")
    })

    $("#customerSearchModal").on("hidden.bs.modal", () => {
      $("#customer-search").val("")
    })

    new autoComplete({
      selector: "#customer-search",
      placeholder: "Cari nama / telepon / IG / FB / Email",
      wrapper: false,
      debounce: 500,
      data: {
        src: async (query) => {
          try {
            const source = await fetch(`${this.autocompletePathValue}?query=${query}`)
            const data = await source.json()

            return data.customers
          } catch(error) {
            return []
          }
        },
        keys: ["search_meta"]
      },
      resultsList: {
        destination: "#searchResult",
        position: "afterbegin",
        class: "list-group list-group-flush tw-shadow tw-rounded-0",
        noResults: true,
        maxResults: 20,
        element: (list, data) => {
          if (data.results.length == 0) {
            const info = `
              <div class="list-group-item tw-border-b-0 tw-text-gray-700 tw-flex tw-flex-col tw-items-center tw-justify-center tw-space-y-4 tw-p-4 tw-py-8">
                <div>
                  ${xCircleSolid("tw-h-5 tw-w-5 tw-mr-2")}
                  <span>
                    Tidak ada hasil untuk <span class="tw-font-semibold">${data.query}</span>
                  </span>
                </div>
              </div>
            ` 
            list.classList.remove("tw-shadow")
            list.prepend(htmlToElement(info));
          }
        }
      },
      resultItem: {
        highlight: true,
        class: "list-group-item list-group-item-action tw-cursor-pointer tw-p-4 tw-border-b",
        element: (item, data) => {
          item.innerHTML = `
            <div class="tw-flex tw-items-center tw-space-x-4">
              <div class="tw-w-[42px] tw-h-auto">
                ${data.value.avatar}
              </div>
              <div class="tw-space-y-1">
                <div class="tw-text-gray-700 tw-font-semibold">${data.value.name}</div>
                <div class="tw-text-gray-700 tw-font-medium">${data.value.phone}</div>
                <div class="tw-text-gray-700">ig: @${data.value.id_instagram}</div>
                <div class="tw-text-gray-700">fb: ${data.value.id_facebook}</div>
                <div class="tw-text-gray-700">email: ${data.value.email}</div>
              </div>
            </div>
          `
        }
      },
      events: {
        input: {
          selection: (event) => {
            let searchField = document.querySelector("#customers-autocomplete")
            let selection = event.detail.selection.value.name
            let url = event.detail.selection.value.path

            searchField.value = selection
            $("#customerSearchModal").modal("hide")
            Turbolinks.visit(url)
          }
        }
      }
    })
  }
}
