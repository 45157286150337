import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    setTimeout(function() {
      $('#planUpgradeInfoModal').modal('show')
    }, 2000)
  }

  redirect(event) {
    $('#planUpgradeInfoModal').modal('hide')
    Turbolinks.clearCache()
    Turbolinks.visit(this.data.get("redirectUrl"))
  }
}
