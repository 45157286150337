import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["startDate", "endDate", "startDateExport", "endDateExport", "filterExport"]

  connect() {
    let thisController = this

    $(this.startDateTarget).on("change", function(e) {
      thisController.startDateExportTarget.value = e.target.value
    })

    $(this.endDateTarget).on("change", function(e) {
      thisController.endDateExportTarget.value = e.target.value
    })
  }

  updateFilterExport(e) {
    this.filterExportTarget.value = e.target.value
  }
}