export function loadingItemSkeleton() {
  return `
    <div class="tw-animate-pulse list-group-item tw-p-4 tw-rounded tw-flex tw-flex-col md:tw-flex-row md:tw-justify-between">
      <div class="tw-space-y-2 tw-flex-1 lg:tw-mr-4">
        <div class="tw-h-2 tw-bg-slate-300 tw-rounded tw-mb-6 tw-w-1/4"></div> 
        <div class="tw-h-2 tw-bg-slate-200 tw-rounded tw-w-1/3"></div> 
        <div class="tw-h-2 tw-bg-slate-200 tw-rounded tw-w-1/2"></div> 
        <div class="tw-h-2 tw-bg-slate-200 tw-rounded tw-w-1/2"></div> 
      </div>
      <div class="tw-mt-4 tw-grid tw-grid-rows-1 tw-grid-cols-2 tw-gap-4 md:tw-mt-0 md:tw-grid-rows-2 md:tw-grid-cols-1">
        <div class="tw-h-8 tw-bg-slate-200 tw-rounded tw-min-w-[8rem]"></div> 
        <div class="tw-h-8 tw-bg-slate-200 tw-rounded tw-min-w-[8rem]"></div> 
      </div>
    </div>
  `
}
