import { Controller } from "@hotwired/stimulus"
import Cleave from "cleave.js"

export default class extends Controller {
  static targets = ["submit", "formDiv", "amountFormatted", "inputFormatted", "inputRaw"]
  static values = {
    amount: String
  }

  connect() {
    let inputFormattedElement = this.inputFormattedTarget
    let inputRawElement = this.inputRawTarget

    this.cleaveJs = new Cleave(inputFormattedElement, {
      numeral: true,
      numericOnly: true,
      numeralDecimalMark: ',',
      delimiter: '.',
      onValueChanged: function (e) {
        inputRawElement.value = e.target.rawValue
      }
    })
  }

  writeRefundAmount(event){
    event.preventDefault()
    const refundAmount = this.amountValue
    this.cleaveJs.setRawValue(refundAmount)
  }

  checkAmountValidity(event){
    setTimeout(() => {
      const currentAmount = parseInt(this.inputRawTarget.value, 10)
      const refundAmount = parseInt(this.amountValue, 10)
      
      if (currentAmount > refundAmount) {
        this.submitTarget.disabled = true
        this.submitTarget.classList.add("tw-btn-muted")
        this.inputFormattedTarget.classList.add('is-invalid')
        this.addErrorMessage()
      }
      else {
        this.submitTarget.disabled = false
        this.submitTarget.classList.remove("tw-btn-muted")
        this.inputFormattedTarget.classList.remove('is-invalid')
        this.removeErrorMessage()
      }
    }, 100)
  }

  removeErrorMessage(){
    let errorDiv = document.getElementById("refund_error_message");
    if (errorDiv) {
      errorDiv.parentNode.removeChild(errorDiv);
    }
  }

  addErrorMessage(){
    if (!document.getElementById("refund_error_message")) {
      let errorDiv = document.createElement("div")
      errorDiv.className = "invalid-feedback tw-text-sm"
      errorDiv.id = "refund_error_message"
      errorDiv.innerHTML = "Refund melebihi jumlah deposit"

      this.formDivTarget.appendChild(errorDiv)
    }
  }
}
