import { Controller } from "@hotwired/stimulus"
import htmlToElement from "../utilities/html_to_element"
import { dateString } from "../packs/date_utils"

export default class extends Controller {
  static values = {
    id: String,
    name: String,
    productName: String,
    maxQuantity: String,
    durationInDays: String,
    priceSuggestion: { type: Number, default: 0 }
  }

  static outlets = ["search-units", "new-order", "order-add-items"]

  connect() {
    if (this.hasSearchUnitsOutlet) {
      if (this.searchUnitsOutlet.hasPriceReminderTarget) {
        this.priceReminderDiv = this.searchUnitsOutlet.priceReminderTarget
      }

      if (this.searchUnitsOutlet.hasInitialItemListTarget) {
        this.initialPrompt = this.searchUnitsOutlet.initialItemListTarget
      }

      this.startDateValue = this.searchUnitsOutlet.startAtDateTarget.value
      this.startTimeValue = this.searchUnitsOutlet.startAtTimeTarget.value

      this.endDateValue = this.searchUnitsOutlet.endAtDateTarget.value
      this.endTimeValue = this.searchUnitsOutlet.endAtTimeTarget.value
    }
  }

  add() {
    const searchUnitsController = this.searchUnitsOutlet
    const id = this.idValue
    const productName = this.productNameValue
    const mSec = (new Date).getTime()
    const startDateCaption = dateString(this.startDateValue, this.startTimeValue)
    const endDateCaption = dateString(this.endDateValue, this.endTimeValue)

    const bookedIds = searchUnitsController.bookedItemGroupIdsValue
    bookedIds.push(id)
    searchUnitsController.bookedItemGroupIdsValue = bookedIds

    // Order item group section
    const orderItemGroupDetail = `
      <div class="mb-3">
        <div class="row mb-2">
          <div class="col-12 col-lg-3 tw-font-semibold tw-text-gray-700">Masa sewa</div>
          <div class="col-12 col-lg-9 tw-text-gray-700">
            ${startDateCaption} - ${endDateCaption}
            <span class="tw-font-semibold">(${this.durationInDaysValue} hari)</span>
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-12 col-lg-3 tw-font-semibold tw-text-gray-700">Nama produk</div>
          <div class="col-12 col-lg-9 tw-text-gray-700">${productName}</div>
        </div>
      </div>
    `

    const inputItemGroupId = `
      <input
        type="hidden"
        id="order_order_item_groups_attributes_${mSec}_item_group_id"
        name="order[order_item_groups_attributes][${mSec}][item_group_id]"
        value="${id}"
      />
    `

    const inputStartAt = `
      <input
        type="hidden"
        id="order_order_item_groups_attributes_${mSec}_start_at"
        name="order[order_item_groups_attributes][${mSec}][start_at]"
        value="${this.startDateValue} ${this.startTimeValue}:00"
      />
    `

    const inputEndAt = `
      <input
        type="hidden"
        id="order_order_item_groups_attributes_${mSec}_end_at"
        name="order[order_item_groups_attributes][${mSec}][end_at]"
        value="${this.endDateValue} ${this.endTimeValue}:59"
      />
    `

    const inputQuantity = `
      <div class="form-group">
        <label
          for="order_order_item_groups_attributes_${mSec}_quantity"
          class="tw-font-semibold tw-text-gray-700"
        >
          Jumlah (Maks. ${this.maxQuantityValue})
        </label>

        <input
          type="number"
          id="order_order_item_groups_attributes_${mSec}_quantity"
          class="tw-form-control"
          name="order[order_item_groups_attributes][${mSec}][quantity]"
          data-action="change->order-item-group#recalculatePricing change->order-item-group#checkQuantity"
          value="1"
        />
      </div>
    `

    const inputPriceIdr = `
      <div class="form-group" data-controller="cleavejs">
        <label for="price-${mSec}" class="tw-font-semibold tw-text-gray-700">Harga sewa</label>

        <input
          type="text"
          inputmode="numeric"
          class="tw-form-control input--price"
          id="price-${mSec}"
          data-cleavejs-target="inputFormatted"
          data-action="focus->menu#handleInputFocus blur->menu#handleInputBlur input->discount-input#calculatePrice"
          value="${ this.priceSuggestionValue }"
        />

        <input
          type="hidden"
          id="order_order_item_groups_attributes_${mSec}_price_idr"
          name="order[order_item_groups_attributes][${mSec}][price_idr]"
          data-discount-input-target="grossPrice"
          data-cleavejs-target="inputRaw"
          value="${ this.priceSuggestionValue }"
        />
      </div>
    `

    const priceSuggestion = `<div id='price_suggestion_${mSec}' class="text-right"></div>`
    const priceSuggestionHTML = htmlToElement(priceSuggestion)
    priceSuggestionHTML.innerHTML = document.getElementById("price_suggestion").innerHTML

    const inputDiscount = `
      <div>
        <div class="form-group" data-controller="cleavejs">
          <label for="discount-price-${mSec}" class="tw-font-semibold tw-text-gray-700">Diskon</label>

          <input
            type="text"
            id="discount-price-${mSec}"
            class="tw-form-control input--price" value="0"
            inputmode="numeric"
            data-cleavejs-target="inputFormatted"
            data-action="focus->menu#handleInputFocus blur->menu#handleInputBlur input->discount-input#calculatePrice"
          />

          <input
            type="hidden"
            id="order_order_item_groups_attributes_${mSec}_discount"
            name="order[order_item_groups_attributes][${mSec}][discount]"
            data-discount-input-target="discount"
            data-cleavejs-target="inputRaw"
            value="0"
          />
        </div>
        <div class="form-group">
          <label
            for="order_order_item_groups_attributes_${mSec}_discount_label"
            class="tw-font-semibold tw-text-gray-700"
          >
            Label Diskon (opsional)
          </label>
          <input
            type="text"
            id="order_order_item_groups_attributes_${mSec}_discount_label"
            name="order[order_item_groups_attributes][${mSec}][discount_label]" class="tw-form-control"
            data-action="focus->menu#handleInputFocus blur->menu#handleInputBlur"
            placeholder="contoh: Promo Gajian"
          />
        </div>
      </div>
    `

    const netPrice = `
      <div class="d-flex align-items-center justify-content-between mb-3">
        <div class="tw-font-semibold tw-text-gray-700">Harga Net:</div>
        <div class="tw-text-gray-700 text-right pr-3">Rp<span data-discount-input-target="netPrice">0</span></div>
      </div>
    `

    const removeButton = `
      <button
        class="tw-btn tw-btn-danger-outline tw-min-w-[8rem]"
        data-action="search-units#removeItemGroup"
        data-item-group-id="${id}"
      >
        <svg xmlns="http://www.w3.org/2000/svg" class="tw-h-4 tw-w-4 -tw-ml-0.5 tw-mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
          <path stroke-linecap="round" stroke-linejoin="round" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
        </svg>
        Hapus
      </button>
    `

    // Construct order item group form field section
    const orderItemGroupDiv = document.createElement("div")
    orderItemGroupDiv.setAttribute("data-controller", "discount-input order-item-group")
    orderItemGroupDiv.setAttribute("data-order-item-group-max-quantity-value", `${this.maxQuantityValue}`)
    orderItemGroupDiv.setAttribute("class", "tw-p-4 tw-border tw-rounded animate-flash-green")

    orderItemGroupDiv.appendChild(htmlToElement(orderItemGroupDetail))
    orderItemGroupDiv.appendChild(htmlToElement(inputItemGroupId))
    orderItemGroupDiv.appendChild(htmlToElement(inputStartAt))
    orderItemGroupDiv.appendChild(htmlToElement(inputEndAt))
    orderItemGroupDiv.appendChild(htmlToElement(inputQuantity))
    orderItemGroupDiv.appendChild(htmlToElement(inputPriceIdr))
    orderItemGroupDiv.appendChild(priceSuggestionHTML)
    orderItemGroupDiv.appendChild(htmlToElement(inputDiscount))
    orderItemGroupDiv.appendChild(htmlToElement(netPrice))
    orderItemGroupDiv.appendChild(htmlToElement(removeButton))

    // Add order item group to order item list
    searchUnitsController.orderItemsListTarget.prepend(orderItemGroupDiv)

    if (searchUnitsController.hasInitialItemListTarget) {
      this.initialPrompt.classList.add("tw-hidden")
    }

    if (searchUnitsController.hasPriceReminderTarget) {
      this.priceReminderDiv.classList.remove("tw-hidden")
    }

    // Remove modal detail
    const modalDetail = document.querySelector(`#item_group_${id}_modal`)
    this.element.parentNode.removeChild(modalDetail)

    this.element.parentNode.removeChild(this.element)

    // Enable submit button only for add items page
    if (this.hasOrderAddItemsOutlet) {
      this.orderAddItemsOutlet.submitButtonTarget.disabled = false
    }

    // enable next button
    if (this.hasNewOrderOutlet) {
      this.newOrderOutlet.orderItemNextCollapseButtonTarget.classList.remove("disabled-next")
    }
  }
}
