import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["submit"]

  handleSelectClassificationChange() {
    let submitButton = this.submitTarget
    submitButton.removeAttribute('disabled')
    submitButton.classList.remove("tw-btn-muted")
    submitButton.classList.add("tw-btn-primary")
  }
}
