import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    clear: Boolean
  }

  connect() {
    let options = {
      container: '.pickadate-container',
      weekdaysShort: ['Min', 'Sen', 'Sel', 'Rab', 'Kam', 'Jum', 'Sab'],
      today: 'Hari ini',
      close: 'Tutup',
      clear: false,
      formatSubmit: '',
      format: 'd-mmm-yyyy'
    }

    if (this.hasClearValue) {
      options.clear = "Hapus"
    }

    $( ".pickadate" ).pickadate(options)
  }
}
