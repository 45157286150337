import * as dateUtils from '../packs/date_utils'
import Rails from '@rails/ujs'
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "startDate", "startTime", "finishDate", "finishTime",
    "start", "finish", "form", "error"
  ]

  static values = { hourly: Boolean }

  connect() {
    const that = this

    $("#start_date").on("change", function() {
      that.startDateTarget.closest(".tw-form-group").classList.remove("field_with_errors")
    })

    $("#end_date").on("change", function() {
      that.finishDateTarget.closest(".tw-form-group").classList.remove("field_with_errors")
    })
  }

  submit(e) {
    e.preventDefault()
    const startDate = this.startDateTarget.value
    const finishDate = this.finishDateTarget.value
    if (!(startDate && finishDate)) {
      this.startDateTarget.closest(".tw-form-group").classList.add("field_with_errors")
      this.finishDateTarget.closest(".tw-form-group").classList.add("field_with_errors")
      return false
    }

    this.prepareQuery(startDate, finishDate);
    this.checkAndSubmit();
  }

  prepareQuery(startDate, finishDate){
    if (this.hasStartTimeTarget && this.hasFinishTimeTarget) {
      const startTime = this.startTimeTarget.value
      const finishTime = this.finishTimeTarget.value

      this.startTarget.value = `${startDate} ${startTime}:00`
      this.finishTarget.value = `${finishDate} ${finishTime}:00`
    } else {
      this.startTarget.value = `${startDate} 00:00:00`
      this.finishTarget.value = `${finishDate} 23:59:59`
    }
  }

  checkAndSubmit() {
    if (this.timeValid()){
      this.formTarget.submit();
    }
    else {
      if (this.hasStartTimeTarget && this.hasFinishTimeTarget) {
        this.startTimeTarget.closest(".tw-form-group").classList.add("field_with_errors")
        this.finishTimeTarget.closest(".tw-form-group").classList.add("field_with_errors")
      }
      this.errorTarget.classList.remove("tw-hidden")
    }  
  }

  timeValid() {
    return  dateUtils.startTimeLessThanEndTime(
      this.startTarget.value, this.finishTarget.value
    );
  }
}
