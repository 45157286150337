
import { Controller } from "@hotwired/stimulus"
import getMetaValue from "../utilities/get_meta_value"

export default class extends Controller {
  static targets = ["listResult", "itemId"]
  static values = {
    itemsUrl: String,
    bookedIds: Array,
    startAtDateTime: String,
    endAtDateTime: String,
    productId: String
  }

  searchItems(e) {
    e.preventDefault()

    const listElement = this.listResultTarget

    if (this.startAtDateTimeValue && this.endAtDateTimeValue && this.productIdValue) {
      listElement.innerHTML = this.listGroup("Loading")

      const options = {
        method: "POST",
        credentials: "same-origin",
        headers: {
          "X-CSRF-Token": getMetaValue("csrf-token"),
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          product_id: this.productIdValue,
          start: this.startAtDateTimeValue,
          finish: this.endAtDateTimeValue,
          booked_ids: this.bookedIdsValue
        })
      }

      setTimeout(() => {
        fetch(this.itemsUrlValue, options)
          .then(response => response.text())
          .then(html => {
            listElement.innerHTML = html
          })
      }, 200);
    } else {
      listElement.innerHTML = this.listGroup("Pastikan tanggal mulai, tanggal selesai, dan produk sudah terisi")
    }
  }

  handleSubmitForm(e) {
    e.preventDefault()

    this.itemIdTarget.value = e.target.dataset.itemId
    document.getElementById("change-item-form").submit()
  }

  listGroup(content) {
    return `
      <div class="list-group list-group-flush">
        <div class="list-group-item">
          ${content}
        </div>
      </div> 
    `
  }
}
