import { Controller } from "@hotwired/stimulus"
import Sortable from "sortablejs"
import { patch } from "@rails/request.js"
import Turbolinks from "turbolinks"

export default class extends Controller {
  static targets = ["list", "submitButton"]
  static values = {
    reloadUrl: String
  }

  connect() {
    this.sortableOptions = {
      onEnd: this.end.bind(this),
      animation: 150,
      ghostClass: "sortable-ghost",
      filter: ".disabled",
      handle: ".sortable-handle",
    }

    this.sortable = Sortable.create(this.listTarget, this.sortableOptions)
  }

  end(event) {
    if (this.hasSubmitButtonTarget) {
      this.submitButtonTargets.forEach(element => {
        element.classList.remove("tw-hidden")
        element.classList.add("tw-grid", "md:tw-flex")
      })
    } else {
      this.submitChange()
    }
  }

  cancelChange() {
    if (this.hasSubmitButtonTarget) {
      this.submitButtonTargets.forEach(element => {
        element.classList.add("tw-hidden")
        element.classList.remove("tw-grid", "md:tw-flex")
      })
      Turbolinks.visit(this.reloadUrlValue)
    }
  }

  async submitChange() {
    let sortedId = this.sortable.toArray()
    let component = this.data.get("component")

    const response = await patch(this.data.get("url"), {
      body: JSON.stringify({
        component: component,
        data: sortedId
      }),
      contentType: "application/json",
      responseKind: "json"
    })

    if (response.ok) {
      if (this.hasSubmitButtonTarget) {
        this.submitButtonTargets.forEach(element => {
          element.classList.add("tw-hidden")
          element.classList.remove("tw-grid", "md:tw-flex")
        })
      }
    }
  }
}
