import { Controller } from "@hotwired/stimulus"
import "@github/clipboard-copy-element"

export default class extends Controller {
  static values = {
    context: String
  }

  static targets = ["button"]

  connect() {
    let context = `Salin ${this.contextValue}`
    $(this.buttonTarget).tooltip({ title: context})
  }

  copy(e) {
    console.log("Copied", e.target.value)
    $(this.buttonTarget).tooltip("dispose")
    $(this.buttonTarget).tooltip({ title:"Berhasil disalin", trigger: "manual" })
    $(this.buttonTarget).tooltip("show")
    this.buttonTarget.innerHTML = `
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="tw-h-5 tw-w-5 tw-text-green-500"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        stroke-width="2"
      >
        <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7" />
      </svg>
    `
    setTimeout(()=> {
      $(this.buttonTarget).tooltip("dispose")
      let context = `Salin ${this.contextValue}`
      $(this.buttonTarget).tooltip({ title: context, trigger: "hover"})
      this.buttonTarget.innerHTML = `
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="tw-h-5 tw-w-5"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          stroke-width="2"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M8 16H6a2 2 0 01-2-2V6a2 2 0 012-2h8a2 2 0 012 2v2m-6
               12h8a2 2 0 002-2v-8a2 2 0 00-2-2h-8a2 2 0 00-2 2v8a2 2 0 002 2z"
          />
        </svg>
      `
    }, 1000)
  }
}
