import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    field: String
  }

  connect() {
    const that = this

    $(that.element).select2({
      placeholder: `Pilih atau ketikan nama ${that.fieldValue}`,
      language: {
        noResults: function () {
          return `Tidak ada ${that.fieldValue} yang sesuai`
        }
      }
    })
  }
}
