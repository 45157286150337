import { Controller } from "@hotwired/stimulus"
import getMetaValue from "../utilities/get_meta_value"

export default class extends Controller {
  static targets = [ "startAt", "endAt", "availableList", "idField", "newCode", "newCodeLine"]
  static values = { productId: String, bookedId: String, itemsUrl: String }

  searchItems(e) {
    e.preventDefault()

    const listElement = this.availableListTarget
    const itemsUrl = this.itemsUrlValue
    const productId = this.productIdValue
    const startAt = this.startAtTarget.value
    const endAt = this.endAtTarget.value

    if (startAt && endAt && productId) {
      listElement.innerHTML = '<p>Loading...</p>'
      const bookedId = this.bookedIdValue

      const options = {
        method: "POST",
        credentials: "same-origin",
        headers: {
          "X-CSRF-Token": getMetaValue("csrf-token"),
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          product_id: productId,
          start: startAt,
          finish: endAt,
          booked_ids: bookedId
        })
      }

      const urlString = `${itemsUrl}?select_action=order-item-change-item%23select`
      fetch(urlString, options)
        .then(response => response.text())
        .then(html => {
          listElement.classList.remove("d-none")
          listElement.innerHTML = html
        })
    }

    else {
      listElement.innerHTML = '<p class="notice"><i class="fa fa-exclamation-circle notice-icon"></i>Pastikan tanggal mulai, selesai, dan produk sudah terisi.</p>'
    }
  }

  select(e) {
    this.availableListTarget.innerHTML = ""
    this.availableListTarget.classList.add("d-none")
    this.newCodeTarget.innerHTML = e.target.dataset.itemCode
    this.newCodeLineTarget.classList.remove("d-none")
    this.idFieldTarget.value = e.target.dataset.itemId
  }
}