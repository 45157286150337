import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets= ['submit', 'warning']

  initialize() {
    if (this.noDateField()) {
      this.warningTarget.innerHTML = 'Maaf, brower Anda tidak mendukung fitur ini. Silahkan perbarui browser Anda. Supersewa merekomendasikan Google Chrome'
      this.submitTarget.disabled = true
    }
  }

  noDateField() {
    const fields = document.querySelectorAll('input[type=date]')
    return fields.length === 0
  }
}
