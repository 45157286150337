import { Controller } from "@hotwired/stimulus"
import Cleave from "cleave.js"

export default class extends Controller {
  static targets = ["inputFormatted", "inputRaw"]
  static values = {
    positiveOnly: Boolean
  }

  connect() {
    let inputFormattedElement = this.inputFormattedTarget
    let inputRawElement = this.inputRawTarget
    let options = {
      numeral: true,
      numericOnly: true,
      numeralDecimalMark: ',',
      delimiter: '.',
      onValueChanged: function (e) {
        inputRawElement.value = e.target.rawValue
      }
    }

    if (this.hasPositiveOnlyValue) {
      options['numeralPositiveOnly'] = true
    }

    this.cleaveJs = new Cleave(inputFormattedElement, options)
  }

  toggleSign(e) {
    let text = this.inputFormattedTarget

    text.classList.add("tw-text-primary", "tw-font-semibold")
    this.cleaveJs.setRawValue(-(this.cleaveJs.getRawValue()))

    setTimeout(() => {
      text.classList.remove("tw-text-primary", "tw-font-semibold")
    }, 300)
  }
}
