import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["pricingSuggestion"]
  static values = {
    maxQuantity: String
  }

  checkQuantity(e) {
    let quantity = parseInt(e.currentTarget.value)
    let maxQuantity = parseInt(this.maxQuantityValue)

    if(isNaN(quantity) || quantity === 0) return e.currentTarget.value = 1
    if(quantity > maxQuantity) return e.currentTarget.value = maxQuantity

    e.currentTarget.value = quantity
  }

  recalculatePricing(e) {
    if (this.hasPricingSuggestionTarget) {
      let quantity = parseInt(e.currentTarget.value)
      let maxQuantity = parseInt(this.maxQuantityValue)
      let currentPricingSuggestion = parseInt(this.pricingSuggestionTarget.dataset.pricingSuggestion)
  
      if(isNaN(quantity) || quantity === 0) {
        this.pricingSuggestionTarget.innerHTML = `Rp${currentPricingSuggestion.toLocaleString("id-ID")}`
        return
      }
      if(quantity > maxQuantity) {
        let formattedPriceSuggestion = (maxQuantity * currentPricingSuggestion).toLocaleString("id-ID")
        this.pricingSuggestionTarget.innerHTML = `Rp${formattedPriceSuggestion}`
        return
      }
  
      let newPricingSuggestion = (quantity * currentPricingSuggestion).toLocaleString("id-ID")
      this.pricingSuggestionTarget.innerHTML = `Rp${newPricingSuggestion}`
    }
  }
}
