import { Controller } from "@hotwired/stimulus"
import Rails from '@rails/ujs'
import autoComplete from "@tarekraafat/autocomplete.js"
import { xCircleSolid } from "../utilities/heroicons"
import htmlToElement from "../utilities/html_to_element"

export default class extends Controller {
  static targets = [
    "ownerName", "customerId", "productCaption",
    "productId", "submitButton", "productBlankNotice",
    "product", "ownerDeleteButton"
  ]

  static values = {
    id: String,
    rentalId: String,
    customersAutocompletePath: String,
    productsAutocompletePath: String
  }
  
  connect() {
    const captionField = this.hasProductTarget ? this.productTarget : null
    const idField = this.hasProductIdTarget ? this.productIdTarget : null
    const thisController = this

    $("#customerSearchModal").on("shown.bs.modal", () => {
      $("#customer-search").trigger("focus")
    })
    $("#customerSearchModal").on("hidden.bs.modal", () => {
      $("#customer-search").val("")
    })

    // FIX browser scroll position when there are 2 pop ups modal 
    //     that hide and open in a short duration
    $(".modal").on("hidden.bs.modal", function(e){
      if ($(".modal:visible").length) {
        $("body").addClass("modal-open")
      }
    });

    $("#productSearchModal").on("shown.bs.modal", () => {
      $("#products-search").trigger("focus")
    })
    $("#productSearchModal").on("hidden.bs.modal", () => {
      $("#products-search").val("")
    })

    new autoComplete({
      selector: "#customer-search",
      placeholder: "Cari nama / telepon / IG / FB / Email",
      wrapper: false,
      debounce: 200,
      data: {
        src: async (query) => {
          try {
            const source = await fetch(`${this.customersAutocompletePathValue}?query=${query}`)
            const data = await source.json()

            return data.customers
          } catch(error) {
            return []
          }
        },
        keys: ["search_meta"]
      },
      resultsList: {
        destination: "#customerSearchResult",
        position: "afterbegin",
        class: "list-group list-group-flush tw-rounded-0",
        noResults: true,
        element: (list, data) => {
          if (data.results.length == 0) {
            const info = `
              <div class="list-group-item tw-border-b-0 tw-text-gray-700 tw-flex tw-flex-col tw-items-center tw-justify-center tw-space-y-4 tw-p-4 tw-py-8">
                <div>
                  ${xCircleSolid("tw-h-5 tw-w-5 tw-mr-2")}
                  <span>
                    Tidak ada hasil untuk <span class="tw-font-semibold">${data.query}</span>
                  </span>
                </div>
              </div>
            ` 
            list.classList.remove("tw-shadow")
            list.prepend(htmlToElement(info));
          }
        }
      },
      resultItem: {
        highlight: true,
        class: "list-group-item list-group-item-action tw-cursor-pointer tw-p-4 tw-border-b",
        element: (item, data) => {
          item.innerHTML = `
            <div class="tw-flex tw-items-center tw-space-x-4">
              <div class="tw-w-[42px] tw-h-auto">
                ${data.value.avatar}
              </div>
              <div class="tw-space-y-1">
                <div class="tw-text-gray-700 tw-font-semibold">${data.value.name}</div>
                <div class="tw-text-gray-700 tw-font-medium">${data.value.phone}</div>
                <div class="tw-text-gray-700">ig: @${data.value.id_instagram}</div>
                <div class="tw-text-gray-700">fb: ${data.value.id_facebook}</div>
                <div class="tw-text-gray-700">email: ${data.value.email}</div>
              </div>
            </div>
          `
        }
      },
      events: {
        input: {
          selection: (event) => {
            let selection = event.detail.selection.value

            this.ownerNameTarget.value = selection.name
            this.customerIdTarget.value = selection.id
            $("#customerSearchModal").modal("hide")
          }
        }
      }
    })

    this.productsAutocomplete()

    $('#new_product').on('submit', function(e) {
      e.preventDefault();
      $.ajax({
        method: 'POST',
        url: $(this).attr('action'),
        data: $(this).serialize(),
        success: function(response) {
          let button = document.getElementById('new_product_submit')
          $('#new_product_modal').modal('toggle')
          Rails.enableElement(button)

          captionField.value = response.brand == null ? response.name : `${response.brand} ${response.name}`
          idField.value = response.id
          thisController.submitButtonTarget.disabled = false
          thisController.submitButtonTarget.classList.remove("tw-btn-muted")
          thisController.submitButtonTarget.classList.add("tw-btn-primary")

          if (thisController.hasProductBlankNoticeTarget) {
            thisController.productBlankNoticeTarget.classList.add("d-none")
          }
        },
        error: function(error) {
          let button = document.getElementById('new_product_submit')
          let flashElement = document.getElementById('flash-messages')
          let errorMessages = error.responseJSON.errors
          let flashContent = `
            <div class="alert alert-danger alert-dismissable fade show">
              <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
              ${errorMessages}
            </div>
          `
          Rails.enableElement(button)
          flashElement.innerHTML = flashContent
        }
      })
    })
  }

  productsAutocomplete() {
    new autoComplete({
      placeHolder: "Cari Merk, Nama Produk",
      selector: "#products-search",
      wrapper: false,
      debounce: 200,
      data: {
        src: async (phrase) => {
          try {
            let url = `${this.productsAutocompletePathValue}?phrase=${phrase}&no_items=true`
            const source = await fetch(url)
            const data = await source.json()

            return data.results
          } catch(error) {
            return []
          }
        },
        keys: ["caption"]
      },
      resultsList: {
        destination: "#searchResult",
        position: "afterbegin",
        class: "list-group list-group-flush tw-shadow tw-rounded-0",
        noResults: true,
        element: (list, data) => {
          if (data.results.length == 0) {
            const info = `
              <div class="list-group-item tw-border-b-0 tw-text-gray-700 tw-flex tw-flex-col tw-items-center tw-justify-center tw-space-y-4 tw-p-4 tw-py-8">
                <div>
                  ${xCircleSolid("tw-h-5 tw-w-5 tw-mr-2")}
                  <span>
                    Tidak ada hasil untuk <span class="tw-font-semibold">${data.query}</span>
                  </span>
                </div>

                <a
                  href="#" class="tw-btn tw-btn-primary tw-min-w-[8rem]"
                  data-new-product-name="${data.query}"
                  data-action="item#openNewProductModal"
                >
                  Tambahkan "${data.query}"
                </a>
              </div>
            ` 
            list.classList.remove("tw-shadow")
            list.prepend(htmlToElement(info));
          }
        }
      },
      resultItem: {
        class: "list-group-item list-group-item-action tw-p-4 tw-border-b",
        highlight: {
            render: true
        },
        element: (item, data) => {
          item.innerHTML = `
            <div class="tw-flex tw-items-start">
              <div class="tw-mr-4">
                <div class="tw-h-auto tw-w-[60px] lg:tw-w-[80px]">
                  <img src="${data.value.picture_url}" class="tw-w-full tw-rounded"/>
                </div>
              </div>
              <div class="tw-space-y-1 lg:tw-space-y-2">
                <div class="tw-text-gray-700 tw-font-medium">${data.match}</div>
                <div class="">${data.value.type}</div>
              </div>
            </div>
          `
        }
      },
      events: {
        input: {
          selection: (event) => {
            let selection = event.detail.selection.value
            let productId = selection.id

            this.productTarget.value = selection.caption
            this.productIdTarget.value = productId
            this.submitButtonTarget.disabled = false
            this.submitButtonTarget.classList.remove("tw-btn-muted")
            this.submitButtonTarget.classList.add("tw-btn-primary")
            $("#productSearchModal").modal("hide")

            if (this.hasProductBlankNoticeTarget) {
              this.productBlankNoticeTarget.classList.add("d-none")
            }
          }
        }
      }
    })
  }

  checkOwner() {
    let owner = this.ownerNameTarget.value
    if (owner == '') {
      this.customerIdTarget.value = ''
    }
  }

  openNewProductModal(event) {
    event.preventDefault()
    $("#product-name").val(event.target.dataset.newProductName)
    $("#productSearchModal").modal("hide")
    $('#new_product_modal').modal()
  }

  updateSubmitLabel(e) {
    let counter = e.target.value

    if(counter.length != 0 && parseInt(counter) > 0 && this.productIdTarget.value.length != 0) {
      this.submitButtonTarget.disabled = false
      this.submitButtonTarget.classList.add("tw-btn-primary")
      this.submitButtonTarget.classList.remove("tw-btn-muted")
      this.submitButtonTarget.value = `Tambah ${counter} unit`
      e.target.classList.remove('is-invalid')
      this.submitButtonTarget.type = "submit"
      this.submitButtonTarget.setAttribute("data-disable-with", `Tambah ${counter} unit`)
    } else {
      this.submitButtonTarget.disabled = true
      this.submitButtonTarget.value = "Tambah unit"
      this.submitButtonTarget.setAttribute("data-disable-with", `Tambah unit`)
      this.submitButtonTarget.type = "button"
      this.submitButtonTarget.classList.remove("tw-btn-primary")
      this.submitButtonTarget.classList.add("tw-btn-muted")
      e.target.classList.add("is-invalid")
    }
  }

  deleteCustomerField(event) {
    event.preventDefault()

    $("#customers-autocomplete").val("")
    $("#item_customer_id").val("")
    this.ownerDeleteButtonTarget.classList.add("tw-hidden")
    alert("Silahkan klik tombol 'Ubah unit' untuk menyimpan perubahan")
  }
}

