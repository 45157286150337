import { Controller } from "@hotwired/stimulus"
import Core from "@uppy/core"
import AwsS3 from "@uppy/aws-s3"
import "summernote/dist/summernote-bs4"
import "summernote/dist/lang/summernote-id-ID"
import "summernote/dist/summernote-bs4.css"

export default class extends Controller {
  connect() {
    const thisController = this

    $("#summernote").summernote({
      dialogsFade: true,
      lang: 'id-ID',
      minHeight: 200,
      styleTags: [
        'p',
        { title: 'Heading 1', tag: 'h1', className: 'text-2xl md:text-3xl lg:text-4xl mb-3', value: 'h1' },
        { title: 'Heading 2', tag: 'h2', className: 'text-xl md:text-2xl lg:text-3xl mb-3', value: 'h2' },
        { title: 'Heading 3', tag: 'h3', className: 'text-xl md:text-xl lg:text-2xl mb-3', value: 'h3' }
      ],
      toolbar: [
        ['style', ['style']],
        ['style', ['bold', 'italic', 'underline', 'clear']],
        ['font', ['strikethrough', 'superscript', 'subscript']],
        ['para', ['ul', 'ol', 'paragraph']],
        ['insert', ['picture', 'link', 'table', 'hr']],
        ['misc', ['undo', 'redo', 'codeview']]
      ],
      callbacks: {
        onImageUpload: function(files) {
          let img = thisController.fileUpload(this, files[0])
        },
        onMediaDelete: function(target, editor, editable) {
          let attachment_id = target[0].id;
          if (!!attachment_id) {
            thisController.deleteFile(target, attachment_id);
          }
          target.remove()
        },
        onPaste: function (event) {
          const bufferText = ((event.originalEvent || event).clipboardData || window.clipboardData).getData("Text");

          event.preventDefault();

          // Firefox fix
          setTimeout(function () {
            document.execCommand("insertText", false, bufferText);
          }, 10);
        }
      }
    })
  }

  deleteFile(target, attachment_id) {
    $.ajax({
      type: "DELETE",
      url: this.data.get('pictureDeletePath').replace("PICTURE_ID", attachment_id),
      accept: 'application/json',
      dataType: 'json',
      cache: false,
      contentType: 'json',
      processData: false
    })
  }

  fileUpload(that, fileInput) {
    const uppy = new Core({
      autoProceed: true,
      allowMultipleUploads: false,
      restrictions: {
        allowedFileTypes: this.data.get('allowedTypes').split(',')
      }
    })

    uppy.addFile({
      name: fileInput.name,
      type: fileInput.type,
      data: fileInput,
      source: 'Local',
      isRemote: false
    })

    uppy.use(AwsS3, {
      companionUrl: '/',
      getUploadParameters(file) {
        let url = `/s3/params?filename=${file.name}&type=${file.type}`;
        return fetch(url, {
          method: 'get',
          headers: {
            accept: 'application/json',
            'content-type': 'application/json'
          }
        })
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          return {
            method: data.method,
            url: data.url,
            fields: data.fields
          };
        });
      },
      locale: {
        strings: {
          chooseFiles: "Pilih Gambar",
          failedToUpload: "Gagal mengunggah gambar %{file}"
        }
      }
    })

    uppy.on('upload-success', (file, response) => {
      const uploadedFileData = {
        id: file.meta['key'].match(/^cache\/(.+)/)[1],
        storage: 'cache',
        metadata: {
          size: file.size,
          filename: file.name,
          mime_type: file.type
        }
      }

      $.ajax({
        type: "POST",
        url: this.data.get('pictureUploadPath'),
        data: {
          picture: {
            image: JSON.stringify(uploadedFileData)
          }
        },
        cache: false,
        dataType: 'json',
        success: function(data){
          let img = document.createElement('IMG');
          img.src = data.summernote_url;
          img.setAttribute('id', data.picture.id);
          $(that).summernote('insertNode', img);
        }
      })
    })
  }
}
