import { Controller } from "@hotwired/stimulus"
import autoComplete from "@tarekraafat/autocomplete.js"
import htmlToElement from "../utilities/html_to_element"
import { xCircleSolid } from '../utilities/heroicons'

export default class extends Controller {
  static values = {
    url: String
  }

  connect() {
    $("#orderSearchModal").on("shown.bs.modal", () => {
      $("#orders-autocomplete").trigger("focus")
    })
    $("#orderSearchModal").on("hide.bs.modal", () => {
      $("#orders-autocomplete").val("")
    })

    new autoComplete({
      selector: "#orders-autocomplete",
      wrapper: false,
      debounce: 300,
      data: {
        src: async (phrase) => {
          try {
            let url = `${this.urlValue}?query=${phrase}`
            const source = await fetch(url)
            const data = await source.json()

            return data.orders
          } catch(error) {
            return []
          }
        },
        keys: ["id_human", "customer_name"]
      },
      resultsList: {
        destination: "#searchResult",
        position: "afterbegin",
        class: "list-group list-group-flush tw-shadow tw-rounded-0",
        noResults: true,
        maxResults: 20,
        element: (list, data) => {
          if (data.results.length == 0) {
            const info = `
              <div class="list-group-item tw-border-b-0 tw-text-gray-700 tw-p-4 tw-py-8 tw-justify-center tw-flex tw-items-center">
                ${xCircleSolid("tw-h-5 tw-w-5 tw-mr-2")}
                <span>
                  Tidak ada hasil untuk <span class="tw-font-semibold">${data.query}</span>
                </span>
              </div>
            ` 
            list.classList.remove("tw-shadow")
            list.prepend(htmlToElement(info));
          }
        }
      },
      resultItem: {
        class: "list-group-item list-group-item-action tw-p-4 tw-border-b",
        highlight: {
            render: true
        },
        element: (item, data) => {
          item.innerHTML = `
            <div class="tw-space-y-4">
              <div class="tw-space-y-2">
                <div class="tw-font-semibold tw-text-gray-700">${data.value.id_human}</div>
                <div class="">${data.value.customer_name}</div>
                <div class="">${data.value.total_price}</div>
              </div>

              <div class="tw-flex tw-flex-wrap tw-gap-x-6 tw-gap-y-2">
                <div class="tw-font-medium tw-flex tw-items-center tw-text-gray-600">
                  ${data.value.statement_date_icon}
                  ${data.value.statement_date}
                </div>

                <div class="tw-font-medium tw-flex tw-items-center ${data.value.paid_status_color}">
                  ${data.value.paid_status_icon}
                  ${data.value.paid_status_text}
                </div>

                <div class="tw-font-medium tw-flex tw-items-center tw-text-gray-600">
                  ${data.value.payment_method_icon}
                  ${data.value.payment_method}
                </div>
              </div>
            </div>
          `
        }
      },
      events: {
        input: {
          selection: (event) => {
            let searchField = document.querySelector("#orders-autocomplete")
            let orderLabel = document.querySelector("#orders-search")
            let selection = event.detail.selection.value
            let url = event.detail.selection.value.path
            searchField.value = selection.id_human
            orderLabel.value = selection.id_human
              
            $("#orderSearchModal").modal("hide")
            Turbolinks.visit(url)
          }
        }
      }
    })
  }
}
