import { Controller } from "@hotwired/stimulus"
import Turbolinks from "turbolinks"

export default class extends Controller {
  static targets = ["modalBody"]

  static values = {
    orderConfirmationUrl: String
  }

  connect() {
    $("#checkout-review").on("hidden.bs.modal", function() {
      Turbolinks.visit(this.orderConfirmationUrlValue)
    })
  }
}
