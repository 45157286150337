import { Controller } from "@hotwired/stimulus"
import Coloris from "@melloware/coloris"

export default class extends Controller {
  connect() {
    Coloris.init()
    this.picker = Coloris({ 
      el: ".color-picker",
      margin: 16,
      alpha: false,
      swatches: [
        "#FF473A",
        "#264653",
        "#2a9d8f",
        "#e9c46a",
        "rgb(244,162,97)",
        "#e76f51"
      ]
    })
  }
}
