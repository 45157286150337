import Rails from "@rails/ujs"
import { Controller } from "@hotwired/stimulus"
import autoComplete from "@tarekraafat/autocomplete.js"
import htmlToElement from "../utilities/html_to_element"
import {
  exclamationCircleOutline,
  minusSmOutline,
  trashOutline,
  userAddOutline,
  xCircleSolid
} from "../utilities/heroicons"

export default class extends Controller {
  static targets = [
    "customerAddressesList", "orderAddress", "orderGmaps",
    "customerName", "existingDepositAlert", "existingDepositAlertLabel",
    "orderItemNextCollapseButton"
  ]

  static values = {
    rentalId: String,
    customersAutocompletePath: String,
    orderDeposits: Array
  }

  connect() {
    const rentalId = this.rentalIdValue
    const controllerDiv = this

    $("#customerSearchModal").on("shown.bs.modal", () => {
      $("#customer-search").trigger("focus")
      $("#customer-search").val(controllerDiv.customerNameTarget.value)
      controllerDiv.customerSearch.start(controllerDiv.customerNameTarget.value)
    })
    $("#customerSearchModal").on("hidden.bs.modal", () => {
      $("#customer-search").val("")
    })

    $('#customer_modal').on('shown.bs.modal', function () {
      $('#customer_name').trigger('focus')
    })

    this.customerAutocomplete()

    $('#new_customer').on('submit', function(e){
      e.preventDefault();
      $.ajax({
        method: 'POST',
        url: $(this).attr('action'),
        data: $(this).serialize(),
        success: function(response){
          let button = document.getElementById('new_customer_submit')
          $('#customer_modal').modal('toggle')
          Rails.enableElement(button)
          $('#customers-autocomplete').val(`${response.name} - ${response.phone.number}`)
          $('#order_customer_id').val(response.id)
          $('#order_address').val(response.address.content)
          $('#order_gmaps_url').val(response.address.gmaps_url)
          $('#existing_customer_collapse').collapse('show')
          $('#address_collapse').collapse('show')
          $('#gmaps_url_collapse').collapse('show')
          const next_button = document.getElementById('customer-next-button')
          next_button.classList.remove('disabled-next')
        },
        error: function(error) {
          let button = document.getElementById('new_customer_submit')
          let flashElement = document.getElementById('flash-messages')
          let errorMessages = error.responseJSON.errors
          let flashContent = `
          <div class="alert alert-error alert-danger alert-dismissible">
            <button type="button" class="close" data-dismiss="alert" aria-hidden="true">×</button>
            <h5><i class="fa fa-exclamation-triangle"></i> Maaf 🙏</h5>
            ${errorMessages}
          </div>
          `
          $('#customer_modal').animate({ scrollTop: 0 }, 'slow');
          Rails.enableElement(button)
          flashElement.innerHTML = flashContent
        }
      });
    });

    // FIX parent modal cant be scrolled when child modal was closed
    $(".modal").on("hidden.bs.modal", function(e){
      if ($(".modal:visible").length) {
        $("body").addClass("modal-open")
      }
    });
  }

  customerAutocomplete() {
    this.customerSearch = new autoComplete({
      selector: "#customer-search",
      placeholder: "Cari nama / telepon / IG / FB / Email tes",
      wrapper: false,
      debounce: 200,
      data: {
        src: async (query) => {
          try {
            const source = await fetch(`${this.customersAutocompletePathValue}?query=${query}`)
            const data = await source.json()

            return data.customers
          } catch(error) {
            return []
          }
        },
        keys: ["search_meta"]
      },
      resultsList: {
        destination: "#customerSearchResult",
        position: "afterbegin",
        class: "list-group list-group-flush tw-rounded-0",
        noResults: true,
        maxResults: 20,
        element: (list, data) => {
          if (data.results.length == 0) {
            const info = `
              <div class="list-group-item tw-border-b-0 tw-text-gray-700 tw-flex tw-flex-col tw-items-center tw-justify-center tw-space-y-4 tw-p-4 tw-py-8">
                <div>
                  ${xCircleSolid("tw-h-5 tw-w-5 tw-mr-2")}
                  <span>
                    Tidak ada hasil untuk <span class="tw-font-semibold">${data.query}</span>
                  </span>
                </div>

                <a
                  href="#" class="tw-btn tw-btn-primary-outline tw-min-w-[8rem]"
                  data-new-product-name="${data.query}"
                  data-action="new-order#openCustomerModal"
                >
                  ${userAddOutline("tw-h-4 tw-w-4 tw-mr-2")}
                  Tambah Pelanggan "${data.query}"
                </a>
              </div>
            ` 
            list.classList.remove("tw-shadow")
            list.prepend(htmlToElement(info));
          }
        }
      },
      resultItem: {
        highlight: true,
        class: "list-group-item list-group-item-action tw-cursor-pointer tw-p-4 tw-border-b",
        element: (item, data) => {
          item.innerHTML = `
            <div class="tw-flex tw-items-center tw-space-x-4">
              <div class="tw-w-[42px] tw-h-auto">
                ${data.value.avatar}
              </div>
              <div class="tw-space-y-1">
                <div class="tw-text-gray-700 tw-font-semibold">${data.value.name}</div>
                <div class="tw-text-gray-700 tw-font-medium">${data.value.phone}</div>
                <div class="tw-text-gray-700">ig: @${data.value.id_instagram}</div>
                <div class="tw-text-gray-700">fb: ${data.value.id_facebook}</div>
                <div class="tw-text-gray-700">email: ${data.value.email}</div>
              </div>
            </div>
          `
        }
      },
      events: {
        input: {
          selection: (event) => {
            const searchField = document.querySelector("#customers-autocomplete")
            const selection = event.detail.selection.value
            const customerID = selection.id
            const customerName = selection.name
            const addresses = selection.addresses
            const customerAddress = addresses[0].content
            const gMapsUrl = addresses[0].gmaps_url
            this.orderDepositsValue = selection.order_deposits

            searchField.value = customerName
            $("#customerSearchModal").modal("hide")

            if (addresses.length > 1) {
              this.customerAddressesListTarget.innerHTML = this.renderCustomerAddressesList(addresses)
              this.orderAddressTarget.classList.add("border", "border-danger")
              this.orderGmapsTarget.classList.add("border", "border-danger")
              this.orderAddressTarget.value = ""
              this.orderGmapsTarget.value = ""
              $("#address_list_collapse").collapse("show")
            } else {
              this.customerAddressesListTarget.innerHTML = ""
              this.customerAddressesListTarget.innerHTML = ""
              this.orderAddressTarget.classList.remove("border", "border-danger")
              $("#address_list_collapse").collapse("hide")
              $("#order_address").val(customerAddress)
              $('#order_gmaps_url').val(gMapsUrl)

              if (this.orderDepositsValue.length > 0) {
                if (this.hasExistingDepositAlertTarget) {
                  this.existingDepositAlertTarget.classList.remove("tw-hidden")
                }
  
                if (this.hasExistingDepositAlertLabelTarget) {
                  this.existingDepositAlertLabelTarget.innerHTML = this.renderOrderDeposit(this.orderDepositsValue)
                }

                setTimeout(() => {
                  $("#collapseAdditional").collapse("toggle")
                }, 800);
              }
            }

            $('#order_customer_id').val(customerID)
            $('#address_collapse').collapse('show')
            $('#gmaps_url_collapse').collapse('show')
            const next_button = document.getElementById('customer-next-button')
            next_button.classList.remove('disabled-next')
          }
        }
      }
    })
  }

  openCustomerModal(event) {
    event.preventDefault()
    $('#customer_modal').modal('show')
    $("#customerSearchModal").modal('hide')
    $('#customer_name').val(event.target.dataset.newProductName)
  }

  insertAdditional(event) {
    event.preventDefault()

    const mSec = (new Date).getTime()
    const additionalCostRow = document.getElementById('additional_costs_row')
    const additionalCostPresets = JSON.parse(event.target.dataset.costPresents).presets

    let additionalCostItem = `
      <div class="additional-cost mb-2 p-3 tw-border tw-rounded">
        <div class="form-group mb-3">
          <label
            for="order_additional_costs_attributes_${mSec}_description"
            class="tw-font-semibold tw-text-gray-700"
          >
            Keterangan
          </label>
          <input
            type="text"
            class="tw-form-control mb-2"
            id="order_additional_costs_attributes_${mSec}_description"
            name="order[additional_costs_attributes][${mSec}][description]"
            placeholder="'Ongkos Kirim', 'Diskon', dsb."
          />
          <div class="tw-snap-x tw-pb-2 tw-space-x-2 tw-overflow-y-hidden tw-overflow-x-scroll tw-whitespace-nowrap"> 
            ${this.renderAdditionalCostBubble(additionalCostPresets, mSec)}
          </div>
        </div>

        <div class="form-group" data-controller="cleavejs">
          <label
            for="price_${mSec}"
            class="tw-font-semibold tw-text-gray-700"
          >
            Harga
          </label>

          <div class="tw-flex tw-rounded">
            <button
              type="button"
              class="tw-text-xs tw-border-gray-300 tw-mr-2 tw-btn tw-px-3
                    tw-btn-primary-outline tw-w-max tw-shadow-none"
              data-action="cleavejs#toggleSign"
              data-toggle="tooltip"
              data-title="Ubah tanda"
            >
              ${minusSmOutline("tw-h-4 tw-w-4")}
            </button>

            <input
              id="price_${mSec}"
              type="text"
              inputmode="numeric"
              class="tw-form-control input--price"
              value="0"
              data-cleavejs-target="inputFormatted"
              data-action="focus->menu#handleInputFocus blur->menu#handleInputBlur"
            />
          </div>

          <input 
            type="hidden"
            value="0"
            data-cleavejs-target="inputRaw"
            id="order_additional_costs_attributes_${mSec}_price"
            name="order[additional_costs_attributes][${mSec}][price]"
          />
        </div>

        <div class="tw-flex tw-items-center tw-justify-end">
          <a
            href="#"
            class="tw-btn tw-btn-danger-outline tw-min-w-[8rem]"
            data-action="click->new-order#removeAdditional"
          >
            ${trashOutline("tw-h-5 tw-w-5 tw-mr-2")}
            Hapus
          </a>
        </div>
      </div>
    `
    additionalCostRow.insertAdjacentHTML('beforeend', additionalCostItem)
    $('[data-toggle="tooltip"]').tooltip({trigger: "hover", placement: "bottom"}) // Initiate tooltip for new additional cost element
  }

  removeAdditional(event) {
    event.preventDefault()

    let item = event.target.closest(".additional-cost")
    item.remove()
  }

  renderAdditionalCostBubble(presets, mSec) {
    let bubbles = []

    for(let i = 0; i < presets.length; i++) {
      bubbles.push(
        `
          <span 
            class="tw-snap-start tw-inline-block tw-font-semibold btn-red tw-py-2
                    tw-cursor-pointer tw-px-4 rounded-pill tw-text-xs"
            data-action="click->new-order#fillAdditionalCostDesc"
            data-description-id="order_additional_costs_attributes_${mSec}_description"
          >
            ${presets[i]}
          </span>
        `
      )
    }

    return bubbles.join(" ")
  }

  renderOrderDeposit(orderDeposits) {
    let orderDepositList = []

    orderDeposits.forEach((orderDeposit) => {
      orderDepositList.push(
        `
         <li>
          Pesanan <a href="${orderDeposit.order_path}" target="_blank">${orderDeposit.id_human}</a>
          sebesar <span class="tw-font-semibold">${orderDeposit.amount}</span>
         </li>
        `
      )
    })

    return orderDepositList.join(" ")
  }

  errorMessage(msg) {
    return `
      <div
        class="tw-bg-yellow-50 tw-border tw-border-yellow-100 tw-text-yellow-700
               tw-rounded p-3 tw-font-semibold tw-flex tw-items-center"
      >
        ${exclamationCircleOutline("tw-h-5 tw-w-5 tw-mr-2 tw-text-yellow-400")}
        ${msg}
      </div>
    `
  }

  fillAdditionalCostDesc(e) {
    e.preventDefault()
    let descriptionId = e.target.dataset.descriptionId
    let descriptionInput = document.getElementById(descriptionId)
    let text = e.target.innerText

    descriptionInput.value = text
  }

  renderCustomerAddressesList(addresses) {
    let list = []

    for(let i = 0; i < addresses.length; i++) {
      list.push(
        `
          <li
            class="list-group-item list-group-item-action tw-cursor-pointer"
            data-action="click->new-order#selectAddress"
          >
            <div class="tw-mb-2 tw-flex tw-items-center tw-justify-between">
              <div class="tw-font-medium tw-text-gray-700">${addresses[i].label}</div>
              <div class="badge badge-info px-2 py-1">${addresses[i].from_catalog}</div>
            </div>
            <div class="content" data-gmaps="${addresses[i].gmaps_url}">
              ${addresses[i].to_s}
            </div>

            <div class="tw-mt-2"><span class="tw-font-semibold tw-text-gray-700">Ongkos kirim:</span> ${addresses[i].shipping_cost}</div>
          </li>
        `
      )
    }

    return list.join(" ")
  }

  selectAddress(event) {
    event.preventDefault()
    let content = event.currentTarget.querySelector(".content")
    let contentText = content.innerText
    let gmapsUrl = content.dataset.gmaps

    $("#address_list_collapse").collapse("hide")
    this.orderAddressTarget.value = contentText
    this.orderGmapsTarget.value = gmapsUrl
    this.orderAddressTarget.classList.remove("border", "border-danger")
    this.orderGmapsTarget.classList.remove("border", "border-danger")

    if (this.orderDepositsValue.length > 0) {
      if (this.hasExistingDepositAlertTarget) {
        this.existingDepositAlertTarget.classList.remove("tw-hidden")
      }

      if (this.hasExistingDepositAlertLabelTarget) {
        this.existingDepositAlertLabelTarget.innerHTML = this.renderOrderDeposit(this.orderDepositsValue)
      }

      setTimeout(() => {
        $("#collapseAdditional").collapse("toggle")
      }, 800);
    }
  }

  changeAddress(event) {
    if (event.target.value =="" && this.customerAddressesListTarget.childNodes.length > 1) {
      this.orderAddressTarget.classList.add("border", "border-danger")
      $("#address_list_collapse").collapse("show")
    }
  }
}
