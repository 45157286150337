import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    $('.datebox-input').datebox({
      mode: "timebox",
      displayMode: "modal",
      useButton: false,
      useFocus: true,
      theme_headerTheme: "bg-white",
      theme_headerBtn: [ "times", "outline-light" ],
      theme_modalContainer: "datebox-container bg-white",
      overrideSetTimeButtonLabel: "Pilih",
      overrideTitleTimeDialogLabel: "Pilih Waktu"
    })
  }
}
