import { Controller } from "@hotwired/stimulus"
import htmlToElement from "../utilities/html_to_element"

export default class extends Controller {
  static targets = ["input", "wrapper"]
  static values = { url: String }

  connect() {
    this.element.append(htmlToElement(`<div class="tw-mt-4 tw-hidden" data-blackout-dates-target="wrapper"></div>`))

    $(this.inputTarget).on("change", () => this.searchHolidays())
  }

  searchHolidays() {
    const date = $(this.inputTarget).pickadate("picker").get()
    const url = `${this.urlValue}?date=${date}`
    const wrapperElement = this.wrapperTarget

    wrapperElement.innerHTML = ""

    fetch(url, {
      headers: {"X-Requested-With": "XMLHttpRequest"},
      credentials: "include"
    })
      .then(res => res.text())
      .then((data) => {
        wrapperElement.classList.remove("tw-hidden")
        wrapperElement.innerHTML = data
      })
  }
}
