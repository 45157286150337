import * as dateUtils from '../packs/date_utils'
import { Controller } from "@hotwired/stimulus"
import { exclamationCircleSolid } from "../utilities/heroicons"

export default class extends Controller {
  static targets= [
    "startAt", "endAt", "endAtDate", "endAtTime",
    "periodLabel", "alert", "suggestionPrice"
  ]

  static values = {
    id: String,
    productId: String,
    calculatorPath: String,
    minEndDate: String,
    maxEndDate: String,
    nextOrderStartAt: String,
    durationDay: Number,
    fieldValidation: Boolean,
    quantity: { type: Number, default: 1 },
    includeStartDateOnDuration: { type: Boolean, default: false }
  }

  static outlets = ["extension-new"]

  connect() {
    const thisController = this

    if (this.hasEndAtDateTarget) {
      $(this.endAtDateTarget).pickadate({
        container: ".pickadate-container",
        weekdaysShort: ["Min", "Sen", "Sel", "Rab", "Kam", "Jum", "Sab"],
        today: "Hari ini",
        clear: false,
        close: "Tutup",
        formatSubmit: '',
        format: "dd-mmm-yyyy",
        min: thisController.minEndDateValue,
        max: thisController.maxEndDateValue
      });

      $(this.endAtDateTarget).on("change", function() {
        thisController.adjustEndAt(
          thisController.endAtDateTarget.value,
          thisController.endAtTimeTarget.value
        )
        thisController.updatePeriod()
        thisController.validateDateTime()
        if (thisController.nextOrderStartAtPresent()) {
          thisController.validateEndAt()
        }
      })
  
      $(this.endAtTimeTarget).on("change", function() {
        thisController.adjustEndAt(
          thisController.endAtDateTarget.value,
          thisController.endAtTimeTarget.value
        )
        thisController.updatePeriod()
        thisController.validateDateTime()
        if (thisController.nextOrderStartAtPresent()) {
          thisController.validateEndAt()
        }
      })
    }

    if (this.hasExtensionNewOutlet && this.fieldValidationValue === false) {
      this.addIdToInvalidFields()
    }
  }

  nextOrderStartAtPresent() {
    return this.nextOrderStartAtValue != "" || this.nextOrderStartAtValue.length != 0
  }

  updatePeriod() {
    this.calculatePeriod()
    this.calculatePrice()
  }

  calculatePrice() {
    const durationDay = this.durationDayValue
    const suggestionElement = this.suggestionPriceTarget
    if (isNaN(durationDay)) {
      suggestionElement.innerHTML = ''
    }
    else {
      const productId = this.productIdValue
      const quantity = this.quantityValue
      const calculatorPath = this.calculatorPathValue
      const urlString = `${calculatorPath}?product_id=${productId}&day=${durationDay}&quantity=${quantity}`
      fetch(urlString,
        { credentials: "include" })
        .then(response => response.text())
        .then(html => {
          suggestionElement.innerHTML = html
        })
    }
  }

  calculatePeriod() {
    const startAt = dateUtils.dateTimeFromStringShort(this.startAtTarget.value)
    const endAt = dateUtils.dateTimeFromStringShort(this.endAtTarget.value)
    const period = parseInt(dateUtils.daysBetween(startAt, endAt), 10)

    this.durationDayValue = this.includeStartDateOnDurationValue ? (period + 1) : period

    if (isNaN(period)) {
      this.periodLabelTarget.innerHTML = ""
    } else {
      this.periodLabelTarget.innerHTML = `untuk ${this.durationDayValue} hari`
    }
  }

  removeItem(event) {
    event.preventDefault()
    this.element.parentNode.removeChild(this.element.previousElementSibling)
    this.element.parentNode.removeChild(this.element)
    this.removeIdFromInvalidFields()
    this.extensionNewOutlet.checkNumberOfExtensionItemFields()
  }

  validateDateTime() {
    let startAt = this.startAtTarget.value
    let endAt = this.endAtTarget.value
    let isValid = dateUtils.startTimeLessThanEndTime(startAt,endAt)

    this.alertTarget.classList.add("tw-hidden")
    this.endAtTimeTarget.closest(".form-group").classList.remove("field_with_errors")
    this.removeIdFromInvalidFields()

    if(!isValid) {
      this.alertTarget.innerHTML = this.errorMessage(
        "Waktu selesai tidak boleh kurang dari waktu mulai."
      )
      this.alertTarget.classList.remove("tw-hidden")
      this.endAtTimeTarget.closest(".form-group").classList.add("field_with_errors")
      this.addIdToInvalidFields()
    }
  }

  validateEndAt() {
    let endAt = this.endAtTarget.value
    let nextOrderStartAt = this.nextOrderStartAtValue
    let isValid = dateUtils.startTimeLessThanEndTime(endAt,nextOrderStartAt)

    this.alertTarget.classList.add("tw-hidden")
    this.endAtTimeTarget.closest(".form-group").classList.remove("field_with_errors")
    this.removeIdFromInvalidFields()

    if(!isValid) {
      this.alertTarget.innerHTML = this.errorMessage(
        `Waktu selesai tidak boleh lebih dari waktu mulai pesanan berikutnya (${this.nextOrderStartAtValue})`
      )
      this.alertTarget.classList.remove("tw-hidden")
      this.endAtTimeTarget.closest(".form-group").classList.add("field_with_errors")
      this.addIdToInvalidFields()
    }
  }

  errorMessage(message) {
    return `
      <div
        class="tw-flex tw-items-center tw-bg-red-50 tw-border tw-border-red-300
              tw-rounded tw-p-4 tw-mb-4 tw-text-red-900 tw-font-medium md:tw-rounded"
      >
        ${exclamationCircleSolid("tw-h-5 tw-w-5 tw-mr-2 tw-text-red-400")}
        ${message}
      </div>
    `
  }

  adjustEndAt(date, time) {
    this.endAtTarget.value = `${date} ${time}:00`
  }

  removeIdFromInvalidFields() {
    let invalidFields = this.extensionNewOutlet.invalidFieldsValue
    invalidFields = invalidFields.filter(e => e !== this.idValue)
    this.extensionNewOutlet.invalidFieldsValue = invalidFields
  }

  addIdToInvalidFields() {
    let invalidFields = this.extensionNewOutlet.invalidFieldsValue
    invalidFields.push(this.idValue)
    this.extensionNewOutlet.invalidFieldsValue = invalidFields
  }
}
