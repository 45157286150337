import { Controller } from "@hotwired/stimulus"
import autoComplete from "@tarekraafat/autocomplete.js"
import { xCircleSolid } from "../utilities/heroicons"
import htmlToElement from "../utilities/html_to_element"

export default class extends Controller {
  static values = {
    productAutocompletePath: String,
    categoryId: String,
    path: String
  }

  connect() {
    $("#newProductCategory").on("shown.bs.modal", () => {
      $("#products-search").trigger("focus")
    })

    $("#newProductCategory").on("hide.bs.modal", () => {
      $("#products-search").val("")
    })

    this.productAutocomplete()
  }

  addProductCategory(productId) {
    $.ajax({
      method: 'POST',
      url: this.pathValue.replace("PRODUCT_ID", productId),
      data: {
        product_category: {
          category_id: this.categoryIdValue,
          product_id: productId
        }
      },
      success: function() {
        // $('#products-autocomplete').blur()
      }
    })
  }

  productAutocomplete() {
    new autoComplete({
      placeHolder: "Cari Nama Produk",
      selector: "#products-search",
      wrapper: false,
      debounce: 200,
      data: {
        src: async (phrase) => {
          try {
            let url = `${this.productAutocompletePathValue}?&phrase=${phrase}&no_items=true`
            const source = await fetch(url)
            const data = await source.json()

            return data.results
          } catch(error) {
            return []
          }
        },
        keys: ["caption"]
      },
      resultsList: {
        destination: "#searchResult",
        position: "afterbegin",
        class: "list-group list-group-flush tw-shadow tw-rounded-0",
        noResults: true,
        maxResults: 20,
        element: (list, data) => {
          if (data.results.length == 0) {
            const info = `
              <div class="list-group-item tw-border-b-0 tw-text-gray-700 tw-p-4 tw-py-8 tw-justify-center tw-flex tw-items-center">
                ${xCircleSolid("tw-h-5 tw-w-5 tw-mr-2")}
                <span>
                  Tidak ada hasil untuk <span class="tw-font-semibold">${data.query}</span>
                </span>
              </div>
            ` 
            list.classList.remove("tw-shadow")
            list.prepend(htmlToElement(info));
          }
        }
      },
      resultItem: {
        class: "list-group-item list-group-item-action tw-p-4 tw-border-b",
        highlight: {
            render: true
        },
        element: (item, data) => {
          item.innerHTML = `
            <div class="tw-flex tw-items-start">
              <div class="tw-mr-4">
                <div class="tw-h-auto tw-w-[60px] lg:tw-w-[80px]">
                  <img src="${data.value.picture_url}" class="tw-w-full tw-rounded"/>
                </div>
              </div>
              <div class="tw-space-y-1 lg:tw-space-y-2">
                <div class="tw-text-gray-700 tw-font-medium">${data.match}</div>
                <div class="">${data.value.type}</div>
              </div>
            </div>
          `
        }
      },
      events: {
        input: {
          selection: (event) => {
            let searchField = document.querySelector("#products-autocomplete")
            let selection = event.detail.selection.value
            this.addProductCategory(selection.id)
            $("#newProductCategory").modal("hide")

            searchField.value = selection.caption
          }
        }
      }
    })
  }
}
